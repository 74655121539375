/*** PS_COM_FE_01 - PS_COM_FE_02 Importing of the necessary packages and the external components*/
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NoRecordsFoundComponent from '../../reusable_components/noRecordsFoundComponent';
import moment from 'moment';
import PaginationComponent from '../../reusable_components/pagination';
import { SortConfig } from '../../Interfaces/work_items_interface';
import { determineColorCode, encryptKeys, encryptStorage, formatArrayDescription, formatDescription, getDurationNameFilters, MinimumFilterDate } from '../../constant';
import { CompletedWorkItemsGridInterface, CompletedGridPayLoad, CompletedDateRangeFilter } from '../../Interfaces/insight_interface';
import { fetchCompletedWorkItems } from '../../services/insights_service';
import ToastMessage from '../../reusable_components/toast';
import exportActivityData from '../../reusable_components/exportExcelComponent';
import { applicationAbbrievation, CompletedDurationFilters } from '../../enums/enums';
import { FullscreenLoader } from "../loader";



/**PS_COM_FE_07 - Declaration of the required state variables */
const CompletedWorkItemsGrid = () => {
    const [completedWorkItemsInfo, setCompletedWorkItemsInfo] = useState<CompletedWorkItemsGridInterface[]>([]);
    const [showFilter, setShowFilter] = useState<boolean>(false);
    const [filterDates, setFilterDates] = useState<CompletedDateRangeFilter>({ fromDate: '', toDate: '' });
    const [passiveFilterDates, setPassiveFilterDates] = useState<CompletedDateRangeFilter>({ fromDate: '', toDate: '' });
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [sortConfig, setSortConfig] = useState<SortConfig>({ key: 'completed_on', direction: 'asc' });
    const [totalCount, setTotalCount] = useState<number>(0);
    const [limit, setLimit] = useState<number>(10);
    const [page, setPage] = useState<number>(1);
    const navigate = useNavigate();
    const [durationFilter, setDurationFilter] = useState<string>('LAST_HOUR')
    const [passiveSearchTerm, setPassiveSearchTerm] = useState<string>('')
    const [showToast, setShowToast] = useState<boolean>(false);
    const [toastType, setToastType] = useState<string>('');
    const [toastMessage, setToastMessage] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    // Retrieve stored user details from encrypted storage
    const userDetailsString = encryptStorage.getItem(encryptKeys?.userDetails);
    const userDetails = JSON.parse(userDetailsString);
    const vemAppId = userDetails.appRole?.find((role: any) => role.abbreviation === applicationAbbrievation.VEM)?.app_uuid || "-";

    /**PS_COM_FE_07 - Callback function to be triggered whenever dependencies change */

    const getAllCompletedWorkItemsInfo = useCallback(async () => {
        const details: CompletedGridPayLoad = {
            sort_column: sortConfig.key,
            sort_order: sortConfig.direction,
            search: searchTerm,
            filter_from_date: filterDates.fromDate,
            filter_to_date: filterDates.toDate,
            number_of_records_per_page: limit,
            page_number: page,
            duration_filter: durationFilter,
            application_uuid: vemAppId
        };
        try {
            setIsLoading(true)
            const response: any = await fetchCompletedWorkItems(details);
            if (response?.status === 200) {
                setCompletedWorkItemsInfo(response.data.data || []);
                setTotalCount(response.data.meta.total || 0);
            } else if (response.response.data.code === 404) {
                setTotalCount(0);
                setCompletedWorkItemsInfo([]);
            }
        } catch (error:any) {
            console.error("Error fetching completed work items", error);
            handleShowToast(true, "Error", error.message);
        }
        finally {
            setIsLoading(false)
        }
    }, [sortConfig, filterDates, limit, page, durationFilter, searchTerm, vemAppId]);

    useEffect(() => {
        getAllCompletedWorkItemsInfo();
    }, [getAllCompletedWorkItemsInfo]);


    /**PS_COM_FE_31 - Function to handle the search Term Change */
    const applySearch = (isCancel: boolean = false) => {
        setPage(1);
        setLimit(10);
        setSearchTerm(isCancel ? '' : passiveSearchTerm); // Set searchTerm to passiveSearchTerm
    };

    /**PS_COM_FE_28 - Function to handle the apply button */
    const handleApplyButton = () => {
        setPage(1);
        setLimit(10);
        setFilterDates(passiveFilterDates)
        setShowFilter(false);
    };


    /**PS_COM_FE_32 to reset date filters and fetch data */
    const cancelFilter = () => {
        setPage(1);
        setLimit(10);
        setFilterDates({ fromDate: '', toDate: '' });
        setPassiveFilterDates({ fromDate: '', toDate: '' });
    };

    /**PS_UA_22 - Function to handle the handleSort */
    const handleSort = (key: string) => {
        setSortConfig((prevConfig: { key: string; direction: string; }) => ({
            key,
            direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
        }));
    };

    /** PS_COM_FE_40 - Callback function to handle page and records per page changes **/
    const handlePageAndRecordsChange = (currentPage: number, recordsPerPage: number) => {
        setPage(currentPage);
        setLimit(recordsPerPage);
    };

    /**PS_UA_22 - Function to handle the renderSortIcon */
    const renderSortIcon = (key: string) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === 'asc'
                ? <img src="img/sort-up-arrow.svg" alt="sort-arrow-up" />
                : <img src="img/sort-down-arrow.svg" alt="sort-arrow-down" />;
        }
        return <img src="img/sort-down-arrow.svg" alt="sort-arrow-down" />;
    };

    /**PS_53 Function for handling toast */
    const handleShowToast = (showToast: boolean, toastType: string, toastMessage: string) => {
        setShowToast(showToast);
        setToastType(toastType);
        setToastMessage(toastMessage);
    };

    /**PS_COM_EX_01 - Function to handle the export CompletedWorkItems  */
    const exportCompletedWorkItem = () => {
        // Define headers for individual Completed WorkItems
        const individualHeaders = [
            { label: 'Workitem', key: 'workItemName', width: 30 },
            { label: 'Processed Time (Sec)', key: 'processedTime', width: 25 },
            { label: 'Customer', key: 'customerName', width: 30 },
            { label: 'Site', key: 'siteName', width: 30 },
            { label: 'Container #', key: 'containerNumbers', width: 25 },
            { label: 'License Plate #', key: 'licensePlate', width: 20 },
            { label: 'Chassis #', key: 'chassisNumbers', width: 25 },
            { label: 'Lane/CHE', key: 'laneChe', width: 15 },
            { label: 'Worked By', key: 'assignedTo', width: 15 },
        ];

        const transformedData = completedWorkItemsInfo?.map((activity: CompletedWorkItemsGridInterface) => ({
            workItemName: activity.work_item_name,
            processedTime: activity.completion_duration_sec,
            customerName: activity.customer_name || '-',
            siteName: activity.site_name || '-',
            containerNumbers: activity.container_numbers,
            licensePlate: activity.license_plate_number || '-',
            chassisNumbers: activity.chassis_numbers || '-',
            laneChe: activity.group_code || '-',
            assignedTo: activity.display_name || "-"
        }));

        // Example success and error callbacks
        const handleSuccessExport = () => {
            handleShowToast(true, "Success", "CompletedWorkItem Details exported successfully");
        };

        const handleErrorExport = () => {
            handleShowToast(false, "Error", "Failed to export CompletedWorkItem Details");
        };

        // Invoke exportActivityData with the correct parameters
        exportActivityData({
            data: transformedData,
            headers: individualHeaders,
            fileName: 'Completed WorkItems.xlsx',
            sheetName: 'Completed WorkItems Report',
            successCallback: handleSuccessExport,
            errorCallback: handleErrorExport
        });
    };

    /**PS_COM_EX_01 - Function to render the completed workItems */
    const renderCompletedWorkItems = () => {
        if (totalCount === 0) {
            return <NoRecordsFoundComponent
                colspan={8}
                mainContent="No Work Items Found"
                subContent="Data will be displayed once user starts working"
                columnHeight='88'
            />
        }

        return (completedWorkItemsInfo?.map?.((item: CompletedWorkItemsGridInterface) => {

            const colorCode = determineColorCode(item.completion_duration_sec+"", item.expected_processing_time_sec+"", item.max_processing_time_sec+"");

            return (<> <tr key={item.work_item_uuid}>
                <td>
                    <span className="d-flex flex-column gap-1">
                        <a href=" " onClick={() => {
                            let json =       {
                                expected_processing_time_sec: item.expected_processing_time_sec,
                                application_uuid: vemAppId,
                                app_work_item_uuid:item.app_work_item_uuid,
                                work_item_type: item.work_item_type,
                                max_processing_time_sec: item.max_processing_time_sec,
                                requeue_time_sec:item.requeue_time_sec ,
                                customer_uuid: item.customer_uuid,
                                customer_name: item.customer_name,
                                site_uuid: item.site_uuid,
                                site_name: item.site_name,
                                lane_id:item.group_uuid,
                                lane_name:item.group_code,
                                requeue_ack_time_sec: item.requeue_ack_time_Sec,
                                confidence_percentage:item.confidence_percentage,
                                work_item_uuid: item.work_item_uuid,
                                portal_data:item.event_data,
                                completed:true,
                                CompletedDurationSec:item.completion_duration_sec
                              }
                              navigate(`/workItem`, {
                                state: json,
                            });
                        }}>
                            {item.work_item_name}{" "}
                        </a>
                        <span className="font-10 color-sub-grey">
                            {item.completed_on ? moment(item.completed_on).format("MM/DD/YYYY HH:mm") : " -"}
                        </span>
                    </span>
                </td>
                <td>
                    <span className={`progress-timer ${colorCode} d-flex align-items-center gap-1`}>
                        <img src="img/timer-icon.svg" alt="timer" width="10px" />
                        <span className="font-bold color-white">{item.completion_duration_sec ? item.completion_duration_sec : 0} sec</span>
                    </span>
                </td>
                <td>
                    <span className="d-flex flex-column gap-1">
                        <span>{item.customer_name ? item.customer_name : "-"}</span>
                        <span className="font-10 color-sub-grey">{item.site_name ? item.site_name : "-"}</span>
                    </span>
                </td>
                <td data-bs-toggle="tooltip" data-bs-placement="right" title={item?.container_numbers ? item.container_numbers : "-"}> {item?.container_numbers
                    ? formatArrayDescription(item.container_numbers)
                    : " -"}</td>
                <td>{item.license_plate_number ? item.license_plate_number : "-"}</td>
                <td data-bs-toggle="tooltip" data-bs-placement="right" title={item?.chassis_numbers ? item.chassis_numbers : "-"}> {item?.chassis_numbers
                    ? formatArrayDescription(item.chassis_numbers ? item.chassis_numbers : "-")
                    : " -"}</td>                <td>{item.group_code ? item.group_code : "-"}</td>
                <td>
                    <span
                        data-bs-placement="right"
                        title={item?.display_name.length > 10 ? item?.display_name : " "}
                        {...(item?.display_name && item?.display_name?.length > 10 ? { 'data-bs-toggle': '' } : {})}
                    >
                        {item?.display_name ? formatDescription(item?.display_name, 10) : "-"}
                    </span>
                </td>
            </tr>
            </>)
        }))
    }

    return (
        <div className={`container-fluid ${isLoading ? 'loader-blur' : ''}`}>
            {isLoading ? (
                <FullscreenLoader />
            ) : (
                <div className="p-3 py-4">
                    <nav aria-label="breadcrumb" className="theme-breadcrumbs">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href=" ">Insights</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Completed Work Items</li>
                        </ol>
                    </nav>            <div className="d-flex align-items-center justify-content-between mb-3">
                        <h2 className="m-0 font-bold font-16 color-black">Completed Work Items</h2>
                        <div className="d-flex align-items-center gap-3">
                            <div className="dropdown time-dpd">
                                <button
                                    type="button"
                                    className="px-3 shadow-btn btn-dpd d-flex align-items-center justify-content-between rounded-2 font-semibold font-14 color-black"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false">
                                    {getDurationNameFilters(durationFilter)} <img src="img/chevron-grey.svg" alt="drp" />
                                </button>
                                <div className="dropdown-menu border-0 custom-shadow px-4 py-3 shadow-sm dropdown-menu-end">
                                    <div className="row font-regular font-13 color-black-v2 g-3">
                                        {Object.values(CompletedDurationFilters)?.map((duration) => (
                                            <div
                                                key={duration} 
                                                className="col-4"
                                                onClick={() => setDurationFilter(duration)}
                                                role="button" 
                                                tabIndex={0}>
                                                <span className={`cursor-pointer ${durationFilter === duration ? 'font-semibold primary-color-blue' : ''}`}>
                                                    {getDurationNameFilters(duration)} {/* Get the display name using the function */}
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="input-group custom-input search-grp rounded-2 overflow-hidden">
                                <input
                                    type="search"
                                    className="form-control border-0 shadow-none font-14"
                                    placeholder="Search"
                                    aria-label="Search"
                                    value={passiveSearchTerm}
                                    onChange={(e) => setPassiveSearchTerm(e.target.value)}
                                    onKeyUp={(event) => event.key === "Enter" ? applySearch() : null}

                                />
                                <span className="input-group-text border-0 bg-transparent" onClick={() => {
                                    applySearch()
                                }}>
                                    <img src="img/search-icon.svg" alt="search" />
                                </span>
                            </div>

                            <div className="dropdown filter-dpd">
                                <button className="shadow-btn rounded-2 d-flex align-items-center justify-content-center gap-2 font-semibold font-14 color-tinted-grey lh-1 px-3" type="button" data-bs-auto-close="true" aria-expanded="true" onClick={() => setShowFilter(!showFilter)}>
                                    <img src="img/filter-icon-blue.svg" alt="filter" className="filter-img" />
                                    <span>Filter</span>
                                </button>
                                {showFilter && (<div className="dropdown-menu border-0 custom-shadow dropdown-menu-end filter-dpd p-3 mt-2 show" style={{ position: 'absolute', right: '-5px' }}>
                                    <div className="d-flex align-items-center justify-content-between mb-3">
                                        <h3 className="m-0 font-bold font-16 color-black-v1">Advanced Filter</h3>
                                        <button className="bg-transparent border-0" type="button" onClick={() => setShowFilter(false)} >
                                            <img src="img/close-icon.svg" alt="close" />
                                        </button>
                                    </div>
                                    <h4 className="mb-3 font-semibold font-14 color-black-v1">Date & Time</h4>
                                    <div className="row g-3">
                                        <div className="col-6">
                                            <label htmlFor="from_date" className="font-semibold font-12 color-black-v1 form-label">From</label>
                                            <input
                                                type="date"
                                                id="from_date"
                                                className="form-control font-12 theme-input shadow-none"
                                                value={passiveFilterDates.fromDate}
                                                min={MinimumFilterDate}
                                                max={passiveFilterDates.toDate ? passiveFilterDates.toDate : new Date().toISOString().split('T')[0]}
                                                onChange={(e) => setPassiveFilterDates({ ...passiveFilterDates, fromDate: e.target.value })}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <label htmlFor="to_date" className="font-semibold font-12 color-black-v1 form-label">To</label>
                                            <input
                                                type="date"
                                                id="to_date"
                                                className="form-control font-12 theme-input shadow-none"
                                                value={passiveFilterDates.toDate}
                                                min={passiveFilterDates.fromDate ? passiveFilterDates.fromDate : MinimumFilterDate}
                                                max={new Date().toISOString().split('T')[0]}
                                                onChange={(e) => setPassiveFilterDates({ ...passiveFilterDates, toDate: e.target.value })}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <div className="d-flex justify-content-end gap-3 mt-5">
                                                <button className="secondary-btn rounded-3" type="button" onClick={() => { cancelFilter() }}>
                                                    <span className="d-inline-block my-1">Clear</span>
                                                </button>
                                                <button className="primary-btn rounded-3" type="button" onClick={() => { handleApplyButton() }} disabled={!passiveFilterDates.fromDate && !passiveFilterDates.toDate}>
                                                    <span className="d-inline-block my-1">Apply</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>)}

                            </div>
                            <button onClick={() => { exportCompletedWorkItem() }} className="shadow-btn export rounded-2 d-flex align-items-center justify-content-center gap-2 font-semibold font-14 color-dark-green lh-1 px-3" type="button">
                                <img src="img/excel-icon.svg" alt="export" />
                                <span>Export</span>
                            </button>
                        </div>
                    </div>

                    {/* Completed Work Items Grid */}
                    <div className="table-responsive bg-white theme-table rounded-3 mb-3">
                        <table className="table m-0 align-middle">
                            <thead>
                                <tr className="alter">
                                    <th>
                                        {completedWorkItemsInfo?.length === 0 ? (
                                            <span>Work Item</span>
                                        ) : (
                                            <span className="cursor-pointer" onClick={() => handleSort('work_item_name')}>
                                                Work Item{" "}{renderSortIcon('work_item_name')}
                                            </span>
                                        )}
                                    </th>
                                    <th>
                                        Processed Time
                                    </th>
                                    <th>
                                        Customer & Site
                                    </th>
                                    <th>
                                        Container #
                                    </th>
                                    <th>
                                        {completedWorkItemsInfo?.length === 0 ? (
                                            <span>License Plate #</span>
                                        ) : (
                                            <span className="cursor-pointer" onClick={() => handleSort('equipment_number')}>
                                                License Plate #{" "}{renderSortIcon('equipment_number')}
                                            </span>
                                        )}
                                    </th>
                                    <th>
                                        Chassis #
                                    </th>
                                    <th>
                                        Lane/CHE
                                    </th>
                                    <th>
                                        {completedWorkItemsInfo?.length === 0 ? (
                                            <span>Worked By</span>
                                        ) : (
                                            <span className="cursor-pointer" onClick={() => handleSort('display_name')}>
                                                Worked By{" "}{renderSortIcon('display_name')}
                                            </span>
                                        )}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {renderCompletedWorkItems()}
                            </tbody>
                        </table>
                    </div>

                    {(completedWorkItemsInfo && completedWorkItemsInfo?.length > 0) && <div className="mt-1"><PaginationComponent
                        totalCount={totalCount}
                        initialRecordsPerPage={limit}
                        setPageAndRecords={(currentPage: number, limit: number) => {
                            handlePageAndRecordsChange(currentPage, limit)
                        }}
                        currentPage={page}
                    /></div>}

                    {showToast && <ToastMessage props={
                        {
                            setIsToast: setShowToast,
                            toastMessage: toastMessage,
                            toastType: toastType
                        }
                    } />
                    }
                </div>
            )}
        </div>
    );
};

export default CompletedWorkItemsGrid;