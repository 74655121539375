/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom"
import PaginationComponent from "../../reusable_components/pagination";
import { getWorkItemSlaByIdService, updateWorkItemSlaService } from "../../services/work_items_sla_service";
import ToastMessage from "../../reusable_components/toast";
import { FullscreenLoader } from "../loader";

export function WorkItemSLAAccordion() {
    const navigate = useNavigate();
    const { state } = useLocation();

    const [payload, setPayload] = useState<any>({
        page_number: 1,
        records_per_page: 10,
        search: "",
        customer_id: state?.data?.customer_uuid,
        site_id: state?.data?.site_uuid,
        application_uuid: state?.application_id
    });

    const [selectedAccordion, setSelectedAccordion] = useState<number | null>(null);
    const [initialMount, setInitialMount] = useState(true);
    const [editWorkItem, setEditWorkItem] = useState<any>();
    const [isEdited, setIsEdited] = useState(false);
    const [toastMessage, setToastMessage] = useState<string>("");
    const [showToast, setShowToast] = useState<boolean>(false);
    const [toastType, setToastType] = useState<string>("");
    const [loader, setLoader] = useState<boolean>(false);

    const [metaData, setMetaData] = useState<any>({
        page: 1,
        page_size: 10,
        total: 0
    })

    const [workItemSLAData, setWorkItemSLAData] = useState<any>();

    const [searchTerm, setSearchTerm] = useState<string>("")

    useEffect(() => {
        getWorkItemSlaDetails();
    }, [payload]);

    const getWorkItemSlaDetails = async () => {
        try {
            const response: any = await getWorkItemSlaByIdService(payload);
            if (response?.status === 200) {
                setWorkItemSLAData(response?.data?.data);
                setMetaData(response?.data?.meta);
            }
            // You might want to set metadata here as well if it comes from the API
        } catch (error: any) {
            console.error("Error fetching work item SLA details:", error);
            setToastMessage(error?.message);
            setToastType("Error")
            setShowToast(true);
            // Handle error (e.g., show error message to user)
        }
    };

    useEffect(() => {
        // Open the first accordion only on initial mount
        if (workItemSLAData && initialMount) {
            setSelectedAccordion(0);
            setEditWorkItem({ ...workItemSLAData[0] });
            setInitialMount(false);
        }
    }, [initialMount, workItemSLAData]);

    useEffect(() => {
        if (editWorkItem && workItemSLAData) {
            var originalItem: any = workItemSLAData?.find(
                (item: any) => item?.work_item_sla_id === editWorkItem?.work_item_sla_id
            );

            if(originalItem === undefined){
                originalItem = {
                    "app_work_item_id": 1001,
                    "work_item_sla_id": "67439b4cc5bd5f0f5b095085",
                    "expected_processing_time_sec": 4,
                    "max_processing_time_sec": 10,
                    "requeue_time_sec": 15,
                    "requeue_ack_time_sec": 5,
                    "work_item_name": "Equipcorr"
                }
            }

            console.log(originalItem);

            const fieldsToCheck: string[] = ['expected_processing_time_sec', 'max_processing_time_sec', 'requeue_time_sec', 'requeue_ack_time_sec'];

            const hasEmptyFields = fieldsToCheck.some(
                (field: string) => editWorkItem[field] === '' || editWorkItem[field] === undefined
            );

            const isChanged = fieldsToCheck.some(
                (field: string) => Number(editWorkItem[field]) !== originalItem[field]
            );

            setIsEdited(isChanged && !hasEmptyFields);
        }
    }, [editWorkItem, workItemSLAData]);

    const handleSearch = (searchValue: string) => {
        setPayload((prev: any) => ({
            ...prev,
            search: searchValue,
            page_number: 1,
            records_per_page: 10,
        }));
    };

    const handleAccordionClick = (index: number) => {
        if (selectedAccordion === index) {
            setSelectedAccordion(null);
            setEditWorkItem(null);
        } else {
            setSelectedAccordion(index);
            setEditWorkItem({ ...workItemSLAData[index] });
        }
        setIsEdited(false);
    };

    function isNumber(value: any) {
        const regex: RegExp = /^[+-]?\d+(\.\d+)?$/;
        return regex.test(value);
    }

    const handleInputChange = (field: string, value: string) => {
        if (value !== "") {
            if (isNumber(value)) {
                setEditWorkItem((prev: any) => ({
                    ...prev,
                    [field]: value
                }));
            }
        }
        else {
            setEditWorkItem((prev: any) => ({
                ...prev,
                [field]: value
            }));
        }
    };

    const handleSave = async () => {
        const payload: any = {
            work_item_sla_id: editWorkItem.work_item_sla_id,
            expected_processing_time_sec: Number(editWorkItem.expected_processing_time_sec),
            max_processing_time_sec: Number(editWorkItem.max_processing_time_sec),
            requeue_time_sec: Number(editWorkItem.requeue_time_sec),
            requeue_ack_time_sec: Number(editWorkItem.requeue_ack_time_sec),
            application_uuid: state?.application_id
        }
        try {
            const response: any = await updateWorkItemSlaService(payload)
            if (response?.status === 200) {
                setToastMessage("SLA details updated successfully");
                setToastType("Success")
                setShowToast(true);
                await getWorkItemSlaDetails()
            }
        }
        catch (err: any) {
            console.error(err)
            setToastMessage(err?.message);
            setToastType("Error")
            setShowToast(true);
        }
        // Here you would typically update the workItemSLAData with the edited item
    };

    const handleCancel = () => {
        if (selectedAccordion !== null) {
            setEditWorkItem({ ...workItemSLAData[selectedAccordion] });
        }
        setIsEdited(false);
    };

    console.log(workItemSLAData)

    const workItemsBinding = () => {
        return workItemSLAData?.map((item: any, index: number) => (
            <div key={item.work_item_sla_id} className="accordion-item custom-shadow rounded-3 overflow-hidden">
                <h2 className="accordion-header">
                    <button
                        className={`accordion-button bg-white d-flex align-items-center gap-2 font-bold font-14 color-black shadow-none ${selectedAccordion !== index && "collapsed"}`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse${index}`}
                        aria-expanded={selectedAccordion === index}
                        aria-controls={`collapse${index}`}
                        onClick={() => handleAccordionClick(index)}
                    >
                        {item?.work_item_name === "Equipment Correction" ? <img src="img/equipment-correction-icon.svg" alt="equipment-correction" /> : <img src="img/ocr-icon.svg" alt="equipment-correction" />}
                        {item?.work_item_name}
                    </button>
                </h2>
                <div
                    id={`collapse${index}`}
                    className={`accordion-collapse collapse ${selectedAccordion === index ? 'show' : ''}`}
                    data-bs-parent="#work_item_SLA_accordion"
                >
                    <div className="accordion-body p-0 pt-3 m-4 mt-0 border-top">
                        <div className="row">
                            <div className="col-11">
                                <div className="row">
                                    {['expected_processing_time_sec', 'max_processing_time_sec', 'requeue_time_sec', 'requeue_ack_time_sec'].map((field) => (
                                        <div key={field} className="col-3">
                                            <div className="d-flex align-items-center gap-2 mb-2 pb-1">
                                                <span className={`d-inline-block process-time ${field === 'max_processing_time_sec' ? 'max' : ''}`} />
                                                <label htmlFor={field} className="m-0 font-semibold font-13 color-black-v1">
                                                    {field.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')} <span className="color-red">*</span>
                                                </label>
                                            </div>
                                            <div className="input-group border rounded-2 overflow-hidden">
                                                <input
                                                    type="text"
                                                    id={field}
                                                    className="form-control shadow-none font-regular font-13 color-black-v1"
                                                    placeholder={`Enter ${field.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}`}
                                                    value={editWorkItem?.[field] || ''}
                                                    maxLength={10}
                                                    onChange={(e: any) => handleInputChange(field, e.target.value)}
                                                />
                                                <span className="input-group-text border-0 font-semibold font-13 color-dark-blue rounded-0">
                                                    Sec
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="d-flex justify-content-end gap-3 mt-4 pt-2">
                                    <button className="secondary-btn lh-base" type="button" onClick={() => handleCancel()}>
                                        Cancel
                                    </button>
                                    <button className="primary-btn lh-base" type="button" onClick={() => handleSave()} disabled={!isEdited}>
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ));
    }

    return (
        loader ? <FullscreenLoader></FullscreenLoader> : <>
            <div className="container-fluid">
                <div className="p-3 py-4">
                    <nav aria-label="breadcrumb" className="theme-breadcrumbs">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <a href="#">Admin</a>
                            </li>
                            <li className="breadcrumb-item">Work Item SLAs</li>
                            <li className="breadcrumb-item active" aria-current="page">
                                {state?.data?.customer_name}
                            </li>
                        </ol>
                    </nav>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <h2 className="m-0 font-bold font-16 color-black d-flex align-items-center gap-2">
                            <a className="mb-1 cursor-pointer" onClick={() => navigate("/workItemSla")}>
                                <img src="img/back-arw.svg" alt="back-arw" />
                            </a>
                            {state?.data?.customer_name} - {state?.data?.site_name}
                        </h2>
                        <div className="d-flex align-items-center gap-3">
                            <div className="input-group custom-input search-grp rounded-2 overflow-hidden">
                                <input
                                    type="search"
                                    className="form-control border-0 shadow-none font-14"
                                    placeholder="Search"
                                    aria-label="Search"
                                    value={searchTerm}
                                    onChange={(event: any) => {
                                        setSearchTerm(event.target.value)
                                    }}
                                    onKeyUp={(event: any) => {
                                        event.key === "Enter" && handleSearch(searchTerm)
                                    }}
                                />
                                <span className="input-group-text border-0 bg-transparent">
                                    <img src="img/search-icon.svg" alt="search" />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div
                        className="accordion theme-accordion d-flex flex-column gap-2 mb-3"
                        id="work_item_SLA_accordion"
                    >
                        {workItemsBinding()}
                    </div>
                    {<div className="mt-1"> <PaginationComponent totalCount={workItemSLAData?.length}
                        initialRecordsPerPage={metaData?.page_size} setPageAndRecords={(currentPage: number, totalCount: number) => {
                            setPayload((prev: any) => ({
                                ...prev, page_number: currentPage, records_per_page: totalCount > 10 ? totalCount : 10
                            }))
                        }}></PaginationComponent></div>}
                </div>
            </div>

            {showToast && (
                <ToastMessage props={{
                    isToast: showToast,
                    setIsToast: setShowToast,
                    toastMessage: toastMessage,
                    toastType: toastType,
                }} />
            )}
        </>
    )
}