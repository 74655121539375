export enum UserRoles {
    supervisor = "SUPERVISOR",
    Operator = "OPERATOR",
    admin = "ADMIN",
    supervisors = "Supervisor",
    admins = "Admin",
    Operators = "Operator",
}

export enum WorkItemStatus {
    Completed = "COMPLETED",
    EscalatedToLane = "ESCALATED_TO_LANE",
    InProgress = "IN_PROGRESS",
    Queued = "QUEUED",
    EscalatedToCustomer = "ESCALATE_TO_CUSTOMER",
    Requeued = "RE_QUEUED"
}

export enum UserStatusType {
    DND = "DND",
    OFFLINE = "OFFLINE",
    BREAK = "BREAK",
    WORKING = "WORKING",
    IDLE = "IDLE",
    ACTIVE = "ACTIVE"
}


export enum applicationAbbrievation {
    VEM = "VEM"
}


export enum UserStatus {
    IDLE = 'IDLE',
    BREAK = 'BREAK',
    DND = 'DND',
    OFFLINE = 'OFFLINE',
    WORKING = 'WORKING',
    ACTIVE = 'ACTIVE',
    LIVEUSERSTATUS = '_LIVE_USER_STATUS'
}

export enum WebsocketCodes {
    NEWWORKITEM = 'NEW_WORKITEM',
    REVOKEWORKITEM = 'REVOKE_WORKITEM',
    STATUSUPDATE = 'STATUS_UPDATE',
    TAKEOVER = 'TAKEOVER_WORKITEM',
    LANEUPDATE = 'LANE_UPDATE'
}

export enum CompletedDurationFilters {
    LASTHOUR = 'LAST_HOUR',
    TODAY = 'TODAY',
    LAST3MONTHS = 'LAST_3_MONTHS',
    LASTWEEK = 'LAST_WEEK',
    THISWEEK = 'THIS_WEEK',
    LAST6MONTHS = 'LAST_6_MONTHS',
    LASTMONTH = 'LAST_MONTH',
    THISMONTH = 'THIS_MONTH',
    LAST12MONTHS = 'LAST_12_MONTHS',
    LASTYEAR = 'LAST_YEAR',
    THISYEAR = 'THIS_YEAR',
}
export enum ActionsEnum {
    VALIDATE = 'VALIDATE',
    DELETE = 'DELETE',
    CREATE = 'CREATE',
    UPDATE = 'UPDATE'
}
