import React, { useState, useEffect } from "react";
import { CountdownTimerProps } from "../../Interfaces/work_item_interface";

//PS_01 to PS_21
export const CountdownTimer = ({
  receivedTime,
  normalTime,
  moderateTime,
  pauseTimer,
  CompletedDurationSec
}: CountdownTimerProps) => {
  const calculateDifference = (time: string) => {
    const pastTime: any = new Date(time);
    const currentTime: any = new Date();
    const timeDifference = currentTime - pastTime;
    return Math.floor(timeDifference / 1000);
  };

  const [seconds, setSeconds] = useState<number>(
    calculateDifference(receivedTime)
  );
  const [level, setLevel] = useState("level-1");

  useEffect(() => {
    if (pauseTimer === true) {
      return
    }
    const intervalId = setInterval(() => {
      setSeconds((prevSeconds) => {
        const currentTime = prevSeconds + 1;
        if (currentTime < normalTime) {
          setLevel("level-1");
        } else if (currentTime >= normalTime && currentTime < moderateTime) {
          setLevel("level-2");
        } else {
          setLevel("level-3");
        }
        return currentTime;
      });
    }, 1000);
    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, [normalTime, moderateTime, pauseTimer]);

  return (
    <div className={`timer-wrapper ${level}`}>
      <div className="timer-content d-flex align-items-center gap-1">
        <img src="img/timer-icon.svg" alt="timer" />
        <span className="font-bold color-white font-18 lh-1">
          {CompletedDurationSec ? CompletedDurationSec : String(seconds).padStart(2, "0")} sec
        </span>
      </div>
    </div>
  );
};