import React, { useState, useEffect } from 'react';
import { WorkItem, EditWorkItemSLARequest, WorkItemSLAPopupProps } from '../../Interfaces/work_items_sla_interface';

const WorkItemSLAPopup: React.FC<WorkItemSLAPopupProps> = ({
  isOpen,
  onClose,
  onSave,
  isEditing,
  initialData,
  customers,
  workItems,
}) => {
  const [editWorkItemSLARequest, setEditWorkItemSLARequest] = useState<EditWorkItemSLARequest>(initialData);
  const [selectedWorkItems, setSelectedWorkItems] = useState<WorkItem[]>([]);
  const [showWorkItemsDropdown, setShowWorkItemsDropDown] = useState<boolean>(false);
  const [initialEditState, setInitialEditState] = useState<EditWorkItemSLARequest | null>(null);

  useEffect(() => {
    setEditWorkItemSLARequest(initialData);
    if (isEditing) {
      setInitialEditState(initialData);
    } else {
      setInitialEditState(null);
    }
    setSelectedWorkItems(
      initialData.work_items
        .filter((item: any) => !item.is_deleted)
        .map((item: any) => workItems.find((wi: any) => wi.app_work_item_uuid === item.work_item_uuid))
        .filter(Boolean) as WorkItem[]
    );
  }, [initialData, workItems, isEditing]);

  const handleCustomerChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCustomer: any = customers.find((c: any) => c.customer_uuid === e.target.value);
    setEditWorkItemSLARequest((prev: any) => ({
      ...prev,
      customer_id: e.target.value,
      site_id: selectedCustomer ? selectedCustomer.site_uuid : prev.site_id
    }));
  };

  const handleWorkItemChange = (workItem: WorkItem, isChecked: boolean) => {
    setEditWorkItemSLARequest((prev: any) => {
      let updatedWorkItems: any;

      if (prev.action === "CREATE") {
        // For CREATE action, simply add or remove items
        if (isChecked) {
          updatedWorkItems = [
            ...prev.work_items,
            {
              work_item_sla_id: "",
              work_item_uuid: workItem.app_work_item_uuid,
              is_deleted: false
            }
          ];
        } else {
          updatedWorkItems = prev.work_items.filter(
            (item: any) => item.work_item_uuid !== workItem.app_work_item_uuid
          );
        }
      } else {
        // For UPDATE action
        const existingItemIndex = prev.work_items.findIndex(
          (item: any) => item.work_item_uuid === workItem.app_work_item_uuid
        );

        if (existingItemIndex !== -1) {
          // Item already exists in the payload
          if (prev.work_items[existingItemIndex].work_item_sla_id) {
            // Existing item: toggle is_deleted
            updatedWorkItems = prev.work_items.map((item: any, index: number) =>
              index === existingItemIndex
                ? { ...item, is_deleted: !isChecked }
                : item
            );
          } else {
            // Newly added item: remove if unchecked
            updatedWorkItems = isChecked
              ? prev.work_items // keep as is if checked
              : prev.work_items.filter((_: any, index: number) => index !== existingItemIndex);
          }
        } else {
          // Item doesn't exist in the payload, add it if checked
          updatedWorkItems = isChecked
            ? [
              ...prev.work_items,
              {
                work_item_sla_id: "",
                work_item_uuid: workItem.app_work_item_uuid,
                is_deleted: false
              }
            ]
            : prev.work_items;
        }
      }

      return {
        ...prev,
        work_items: updatedWorkItems
      };
    });

    setSelectedWorkItems((prev: any) =>
      isChecked
        ? [...prev, workItem]
        : prev.filter((item: any) => item.app_work_item_uuid !== workItem.app_work_item_uuid)
    );
  };

  const handleSave = () => {
    onSave(editWorkItemSLARequest);
  };

  const getSelectedWorkItemsDisplay = () => {
    const names = selectedWorkItems.map((item: any) => item.work_item_name).join(', ');
    if (names.length <= 30) return names;
    return names.substring(0, 30) + '...';
  };

  const isAddButtonDisabled = () => {
    return editWorkItemSLARequest.customer_id === "" || editWorkItemSLARequest.work_items.length === 0;
  };

  const isEditButtonDisabled = () => {
    if (!initialEditState) return true;
    if (editWorkItemSLARequest.customer_id === "") return true;
    if (editWorkItemSLARequest.work_items.filter((item: any) => !item.is_deleted).length === 0) return true;
    return JSON.stringify(initialEditState) === JSON.stringify(editWorkItemSLARequest);
  };

  if (!isOpen) return null;

  return (
    <div className="modal fade show active custom-popup" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)", backdropFilter: "blur(1px)" }}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title font-16 font-bold color-black">
              {isEditing ? "Edit Work Item SLA" : "Add SLA"}
            </h1>
            <button type="button" className="btn-close" onClick={() => { setShowWorkItemsDropDown(false); onClose() }} />
          </div>
          <div className="modal-body custom-popup-form">
            <div className="row justify-content-between">
              <div className="col-6">
                <label htmlFor="_Customer" className="m-0 font-semibold font-14 color-black-v1 mb-2">
                  Customer <span className="color-red">*</span>
                </label>
                <select
                  className="form-select font-13 custom-input shadow-none border"
                  id="_Customer"
                  onChange={(event: any) => handleCustomerChange(event)}
                  value={editWorkItemSLARequest.customer_id}
                  disabled={isEditing}
                >
                  <option value="">Select Customer</option>
                  {customers.map((customer: any) => (
                    <option key={customer.customer_uuid} value={customer.customer_uuid}>{customer.customer_name} - {customer.site_name}</option>
                  ))}
                </select>
              </div>
              <div className="col-md-6 field-size">
                <label htmlFor="time-zone" className="m-0 font-semibold font-13 color-black-v1 mb-2">
                  Work Item <span className="color-red">*</span>
                </label>
                <div className="position-relative">
                  <div
                    className="checkbox-select form-select custom-input shadow-none border font-12 d-flex align-items-center"
                    onClick={() => setShowWorkItemsDropDown(!showWorkItemsDropdown)}
                    title={selectedWorkItems.map(item => item.work_item_name).join(', ')}
                  >
                    <span className="d-flex text-nowrap overflow-auto">
                      {selectedWorkItems.length > 0 ? getSelectedWorkItemsDisplay() : 'Select'}
                    </span>
                  </div>
                  <div className={`checkbox-select-option position-absolute ${!showWorkItemsDropdown && "d-none"}`}>
                    {workItems.map((item: WorkItem, index: number) => (
                      <ul key={item.app_work_item_uuid} className="list-unstyled mb-0">
                        <li className={`${index !== 0 && "mt-3"}`}>
                          <div className="form-check d-flex align-items-center">
                            <input
                              className="form-check-input shadow-none popup-check-input"
                              type="checkbox"
                              value={item.app_work_item_uuid}
                              id={`_select_customers_${index}`}
                              onChange={(e) => handleWorkItemChange(item, e.target.checked)}
                              checked={selectedWorkItems.some((wi: any) => wi.app_work_item_uuid === item.app_work_item_uuid)}
                            />
                            <label
                              className="form-check-label font-semibold font-12 color-grey-v4 ms-2 mt-1"
                              htmlFor={`_select_customers_${index}`}
                            >
                              {item.work_item_name}
                            </label>
                          </div>
                        </li>
                      </ul>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer mt-5">
            <div className="col-md-12">
              <div className="d-flex justify-content-end gap-3 mt-2 mb-3">
                <button className="secondary-btn rounded-3" type="button" onClick={() => { setShowWorkItemsDropDown(false); onClose() }}>
                  <span className="d-inline-block my-1">Cancel</span>
                </button>
                <button
                  className="primary-btn rounded-3"
                  type="button"
                  onClick={() => { setShowWorkItemsDropDown(false); handleSave() }}
                  disabled={isEditing ? isEditButtonDisabled() : isAddButtonDisabled()}
                >
                  <span className="d-inline-block my-1">{isEditing ? "Edit" : "Add"}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkItemSLAPopup;