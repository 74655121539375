/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { createBreakTemplateService, deleteBreakTemplateService, getAssociatedTeamsWithBreakTemplateService, getBreakTemplateByUUIDService, getBreakTemplatesService, updateBreakTemplateService } from "../../services/break_templates_service";
import { BreakTemplateResponse, CreateBreakTemplateRequest, CreateBreakTimingRequest, GetRequestPayload, Meta, UpdateBreakTemplateRequest, UpdateBreakTimingRequest } from "../../Interfaces/break_templates_interface";
import moment from "moment";
import PaginationComponent from "../../reusable_components/pagination";
import NoRecordsFoundComponent from "../../reusable_components/noRecordsFoundComponent";
import ToastMessage from "../../reusable_components/toast";
import DeletionConfirmationPopup from "../../reusable_components/delete_popup";

export function BreakTemplates() {
    // PS_BT_01: Initialize state variables
    const [breakTemplates, setBreakTemplates] = useState<BreakTemplateResponse[]>();
    const [selectedOption, setSelectedOption] = useState('');
    const initialPayloadData: GetRequestPayload = {
        search_value: '',
        created_date_from: '',
        created_date_to: '',
        modified_date_from: '',
        modified_date_to: '',
        sort_column: 'modified_on',
        sort_order: 'desc',
        page_number: 1,
        records_per_page: 10,
    }
    const [getRequestPayload, setGetRequestPayload] = useState<GetRequestPayload>(initialPayloadData);
    const initialBreakTemplateRequest: CreateBreakTemplateRequest = {
        break_name: "",
        break_timings: []
    }
    const initialUpdateRequest: UpdateBreakTemplateRequest = {
        break_template_uuid: "",
        break_timings: [],
        break_name: ""
    }
    const [createBreakTemplateRequest, setCreateBreakTemplateRequest] = useState<CreateBreakTemplateRequest>(initialBreakTemplateRequest);
    const [updateBreakTemplateRequest, setUpdateBreakTemplateRequest] = useState<UpdateBreakTemplateRequest>(initialUpdateRequest);
    const [checkUpdateBreakTemplateRequest, setCheckUpdateBreakTemplateRequest] = useState<UpdateBreakTemplateRequest>(initialUpdateRequest);
    const [deleteBreakTemplateID, setDeleteBreakTemplateID] = useState<string>('');
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [dateRange, setDateRange] = useState<GetRequestPayload>(initialPayloadData);
    const [showDateFilter, setShowDateFilter] = useState<boolean>(false)
    const [hideSort, setHideSort] = useState<string>("");
    const [state, setState] = useState<string>("");
    const [showAssociatedTeamsAlertPopup, setShowAssociatesTeamsAlertPopup] = useState<boolean>(false);
    const [associatedTeams, setAssociatedTeams] = useState<string>("");
    const [showConfirmDeletion, setShowConfirmDeletion] = useState<boolean>(false);
    const [createBreakTiming, setCreateBreakTiming] = useState<CreateBreakTimingRequest>({
        break_start_time: "",
        duration_minutes: 0
    });
    const [editIndex, setEditIndex] = useState<number | null>();
    const [editBreak, setEditBreak] = useState<CreateBreakTimingRequest>({
        break_start_time: "",
        duration_minutes: 0
    });
    const [metaData, setMetaData] = useState<Meta>({
        page: 1,
        page_size: 10,
        total: 0
    })
    const [durationSort, setDurationSort] = useState<string>("")
    const [startTimeSort, setStartTimeSort] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [showCreateEditPopup, setShowCreateEditPopup] = useState<boolean>(false);
    const [toastMessage, setToastMessage] = useState<string>("");
    const [showToast, setShowToast] = useState<boolean>(false);
    const [toastType, setToastType] = useState<string>("")

    // PS_BT_02: Trigger getBreakTemplates function on mount and when getRequestPayload changes
    useEffect(() => {
        getBreakTemplates(); // PS_BT_15
    }, [getRequestPayload]);

    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    // Call this function whenever updateBreakTemplateRequest or checkUpdateBreakTemplateRequest changes
    useEffect(() => {
        const hasChanges = hasBreakTemplateChanges(updateBreakTemplateRequest, checkUpdateBreakTemplateRequest);
        setIsButtonDisabled(hasChanges);
    }, [updateBreakTemplateRequest, checkUpdateBreakTemplateRequest]);

    const hasBreakTemplateChanges = (
        updateBreakTemplateRequest: any,
        checkUpdateBreakTemplateRequest: any
    ): boolean => {
        // Check if break names are different
        if (updateBreakTemplateRequest.break_name !== checkUpdateBreakTemplateRequest.break_name) {
            return true;
        }

        // Check if break_timings arrays have different lengths
        if (updateBreakTemplateRequest.break_timings.length !== checkUpdateBreakTemplateRequest.break_timings.length) {
            return true;
        }

        // Compare each break timing
        for (let i = 0; i < updateBreakTemplateRequest.break_timings.length; i++) {
            const updateTiming = updateBreakTemplateRequest.break_timings[i];
            const checkTiming = checkUpdateBreakTemplateRequest.break_timings[i];

            // Compare duration_minutes
            if (updateTiming.duration_minutes !== checkTiming.duration_minutes) {
                return true;
            }

            // Compare break_start_time using formatStartTime function
            const updateFormattedTime = formatStartTime(updateTiming.break_start_time);
            const checkFormattedTime = formatStartTime(checkTiming.break_start_time);

            if (updateFormattedTime !== checkFormattedTime) {
                return true;
            }
        }

        // If no differences found, return false
        return false;
    }

    // PS_BT_03 & PS_BT_04: Prepare payload and call service to fetch break templates
    const getBreakTemplates = async () => {
        const payload: GetRequestPayload = {
            search_value: getRequestPayload.search_value,
            created_date_from: getRequestPayload.created_date_from,
            created_date_to: getRequestPayload.created_date_to,
            modified_date_from: getRequestPayload.modified_date_from,
            modified_date_to: getRequestPayload.modified_date_to,
            sort_column: getRequestPayload.sort_column,
            sort_order: getRequestPayload.sort_order,
            page_number: getRequestPayload.page_number,
            records_per_page: getRequestPayload.records_per_page
        }
        try {
            const response: any = await getBreakTemplatesService(payload); // PS_BT_04
            if (response?.data?.code === 200) {
                // PS_BT_13: Update state with received data and bind for display
                setBreakTemplates(response?.data?.data);
                setMetaData(response?.data?.meta);
            } else if (response?.status === 204) {
                // PS_BT_14: Handle no records found
                if (getRequestPayload.page_number !== 1) {
                    setGetRequestPayload((prev: GetRequestPayload) => ({
                        ...prev, page_number: prev.page_number - 1, records_per_page: 10
                    }))
                }
                else {
                    setBreakTemplates([]);
                }
            }
            else {
                setShowCreateEditPopup(false);
                setToastMessage(response?.message);
                setToastType("Error")
                setShowToast(true);
            }
        } catch (error: any) {
            // PS_BT_12: Log error to console
            console.error('Error fetching break templates:', error);
            setToastMessage(error.message);
            setToastType("Error")
            setShowToast(true);
        }
    };

    const getBreakTemplateByuuid = async (break_template_uuid: string) => {
        try {
            let response: any = await getBreakTemplateByUUIDService(break_template_uuid);
            if (response?.data?.code === 200) {
                setUpdateBreakTemplateRequest(response?.data?.data)
                setCheckUpdateBreakTemplateRequest(response?.data?.data)
            }
            else {
                setShowCreateEditPopup(false);
                setToastMessage(response?.message);
                setToastType("Error")
                setShowToast(true);
            }
        }
        catch (error: any) {
            console.error('Error fetching break template by uuid: ', error)
            setToastMessage(error.message);
            setToastType("Error")
            setShowToast(true);
        }
    }

    const getAssociatedTeamsWithBreakTemplate = async (deleteBreakTemplateuuid: string) => {
        try {
            const response: any = await getAssociatedTeamsWithBreakTemplateService(deleteBreakTemplateuuid);
            if (response?.data?.code === 200) {
                setAssociatedTeams(response?.data?.data?.map((team: any) => team?.team_name).join(', '))
                setShowAssociatesTeamsAlertPopup(true)
            }
            else if (response.status === 204) {
                setShowConfirmDeletion(true);
            }
            else {
                setShowCreateEditPopup(false);
                setToastMessage(response?.message);
                setToastType("Error")
                setShowToast(true);
            }
        }
        catch (error: any) {
            console.error('Error fetching Associated teams: ', error)
            setToastMessage(error.message);
            setToastType("Error")
            setShowToast(true);
        }
    }

    const createBreakTemplate = async () => {
        const payload: CreateBreakTemplateRequest = {
            break_name: createBreakTemplateRequest.break_name,
            break_timings: createBreakTemplateRequest.break_timings,
        }
        try {
            const response: any = await createBreakTemplateService(payload);
            if (response?.status === 201) {
                setShowCreateEditPopup(false);
                setCreateBreakTemplateRequest({ break_name: "", break_timings: [] });
                setErrorMessage("");
                setToastMessage("Template added successfully");
                setToastType("Success")
                setShowToast(true);
            }
            else if (response?.code === 409) {
                setErrorMessage(response?.message)
            }
            else {
                setShowCreateEditPopup(false);
                setToastMessage(response?.message);
                setToastType("Error")
                setShowToast(true);
            }
        }
        catch (error: any) {
            console.error(error);
            setShowCreateEditPopup(false);
            setToastMessage(error.message);
            setToastType("Error")
            setShowToast(true);
        }
        finally {
            await getBreakTemplates()
        }
    }

    const updateBreakTemplate = async () => {
        const payload: UpdateBreakTemplateRequest = {
            break_template_uuid: updateBreakTemplateRequest.break_template_uuid,
            break_name: updateBreakTemplateRequest.break_name,
            break_timings: updateBreakTemplateRequest.break_timings
        }
        try {
            const response: any = await updateBreakTemplateService(payload);
            if (response?.status === 200) {
                setUpdateBreakTemplateRequest(initialUpdateRequest);
                setCheckUpdateBreakTemplateRequest(initialUpdateRequest)
                setShowCreateEditPopup(false);
                setErrorMessage("")
                setToastMessage("Template updated successfully");
                setToastType("Success")
                setShowToast(true);
            }
            else if (response?.code === 409) {
                setErrorMessage(response?.message)
            }
            else {
                setShowCreateEditPopup(false);
                setToastMessage(response?.message);
                setToastType("Error")
                setShowToast(true);
            }
        }
        catch (error: any) {
            console.error("Error Updating Break Template", error);
            setShowCreateEditPopup(false);
            setToastMessage(error.message);
            setToastType("Error")
            setShowToast(true);
        }
        finally {
            await getBreakTemplates()
        }
    }

    const deleteBreakTemplate = async () => {
        try {
            const response: any = await deleteBreakTemplateService(deleteBreakTemplateID);
            if (response?.status === 200) {
                setToastMessage("Template deleted successfully");
                setToastType("Success")
                setShowToast(true);
            }
            else {
                setShowCreateEditPopup(false);
                setToastMessage(response?.message);
                setToastType("Error")
                setShowToast(true);
            }
        }
        catch (error: any) {
            console.error("Error deleting break template: ", error)
            setToastMessage(error.message);
            setToastType("Error")
            setShowToast(true);
        }
        finally {
            getBreakTemplates();
            setShowConfirmDeletion(false);
        }
    }

    const cancelDeleteBreakTemplate = async () => {
        setShowConfirmDeletion(false);
        setDeleteBreakTemplateID("");
    }

    const onChangeDataSetter = (event: React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLInputElement>) => {
        var { name, value } = event.target
        if (state === "Create") {
            setCreateBreakTemplateRequest((prev: CreateBreakTemplateRequest) => ({
                ...prev,
                [name]: value,
            }))
        }
        else {
            setUpdateBreakTemplateRequest((prev: UpdateBreakTemplateRequest) => ({
                ...prev,
                [name]: value,
            }))

        }
    }

    const getBreakTimingValue = (timeValue: string) => {
        // Extract the time part from the ISO string
        if (timeValue.includes('T')) {
            const timePart = timeValue.split('T')[1].split('.')[0];
            const [hours, minutes] = timePart.split(':');
            // Pad with leading zeros if necessary
            const formattedHours = hours.padStart(2, '0');
            const formattedMinutes = minutes.padStart(2, '0');
            return `${formattedHours}:${formattedMinutes}`;
        }
        else return timeValue
    }

    const onChangeTimingRequestData = (event: React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLInputElement>) => {

        var { name, value } = event.target
        if (name === "break_start_time") {
            value = value?.length === 5 ? `${value}:00` : value;
        }

        setSelectedOption(value);
        setCreateBreakTiming((prev: CreateBreakTimingRequest) => ({
            ...prev,
            [name]: name === "break_start_time" ? value : Number(value),
        }))
    }

    const addBreakTiming = () => {
        if (state === "Create") {
            setCreateBreakTemplateRequest((prevRequest: CreateBreakTemplateRequest) => ({
                ...prevRequest,
                break_timings: [...prevRequest.break_timings, createBreakTiming],
            }));
            setCreateBreakTiming({
                break_start_time: "",
                duration_minutes: 0
            })
        } else {
            setUpdateBreakTemplateRequest((prevRequest: UpdateBreakTemplateRequest) => ({
                ...prevRequest,
                break_timings: [...prevRequest.break_timings, createBreakTiming],
            }));
            setCreateBreakTiming({
                break_start_time: "",
                duration_minutes: 0
            })
        }
    };

    // Function to delete a break timing by index
    const deleteBreakTiming = (index: number) => {
        if (state === "Create") {
            setCreateBreakTemplateRequest((prevRequest: CreateBreakTemplateRequest) => ({
                ...prevRequest,
                break_timings: prevRequest.break_timings.filter((_: any, i: number) => i !== index),
            }));
        }
        else {
            setUpdateBreakTemplateRequest((prevRequest: UpdateBreakTemplateRequest) => ({
                ...prevRequest,
                break_timings: prevRequest.break_timings.filter((r: UpdateBreakTimingRequest, i: number) => i !== index),
            }));
        }
    };

    // Function to handle changes to individual break timings
    const handleBreakTimingChange = (index: number, editBreak: CreateBreakTimingRequest) => {
        if (state === "Create") {
            setCreateBreakTemplateRequest((prevRequest: CreateBreakTemplateRequest) => ({
                ...prevRequest,
                break_timings: prevRequest.break_timings.map((timing: any, i: number) =>
                    i === index ? { ...timing, break_start_time: editBreak.break_start_time, duration_minutes: editBreak.duration_minutes } : timing
                ),
            }))
        } else {
            setUpdateBreakTemplateRequest((prevRequest: UpdateBreakTemplateRequest) => ({
                ...prevRequest,
                break_timings: prevRequest.break_timings.map((timing: UpdateBreakTimingRequest, i: number) =>
                    i === index ? { ...timing, break_start_time: editBreak.break_start_time, duration_minutes: editBreak.duration_minutes } : timing
                ),
            }))
        }
    }

    const sortBreakTimingsByDuration = (request: any, order: string): void => {
        setDurationSort(order)
        request.break_timings.sort((a: any, b: any) => {
            // First, compare by duration (descending order)
            const durationA = parseInt(a.duration_minutes, 10);
            const durationB = parseInt(b.duration_minutes, 10);
            if (durationA !== durationB) {
                if (order === "asc") return durationA - durationB;
                return durationB - durationA
            }

            // If durations are equal, compare by start time (ascending order)
            const timeA = new Date(a.break_start_time).getTime();
            const timeB = new Date(b.break_start_time).getTime();
            if (order === "asc") return timeA - timeB;
            return timeB - timeA
        });
        state === "Create" ? setCreateBreakTemplateRequest((prev: CreateBreakTemplateRequest) => ({ ...prev, break_timings: request.break_timings })) : setUpdateBreakTemplateRequest((prev: UpdateBreakTemplateRequest) => ({ ...prev, break_timings: request.break_timings }))
    };

    const sortBreakTimingsByStartTime = (request: any, order: string): void => {
        setStartTimeSort(order);
        request.break_timings.sort((a: any, b: any) => {
            // Extract hours and minutes from the break_start_time string
            const [hoursA, minutesA] = a.break_start_time.includes('T') ? a.break_start_time.split('T')[1].split(':').map(Number) : a.break_start_time.split(':').map(Number);
            const [hoursB, minutesB] = b.break_start_time.includes('T') ? b.break_start_time.split('T')[1].split(':').map(Number) : b.break_start_time.split(':').map(Number);

            // Compare hours
            if (hoursA !== hoursB) {
                if (order === "asc") return hoursA - hoursB;
                return hoursB - hoursA
            }

            // If hours are equal, compare minutes
            else {
                if (order === "asc") return minutesA - minutesB;
                return minutesB - minutesA
            }
        });
        state === "Create" ? setCreateBreakTemplateRequest((prev: CreateBreakTemplateRequest) => ({ ...prev, break_timings: request.break_timings })) :
            setUpdateBreakTemplateRequest((prev: UpdateBreakTemplateRequest) => ({ ...prev, break_timings: request.break_timings }));

    };

    const breakTemplatesBinding = () => {
        return (breakTemplates?.map((template: BreakTemplateResponse, index: number) => {
            return (
                <tr>
                    <td className={template?.break_name?.length > 30 ? "cursor-pointer" : ""} title={template.break_name?.length > 30 ? template.break_name : ""}>{template.break_name?.length > 30 ? template.break_name.substring(0, 30) + "..."
                        : template.break_name}</td>
                    <td>
                        {breakTimingsBinding(template.break_timings)}
                    </td>
                    <td title={template.created_by?.length > 30 ? template.created_by : ""}>{template.created_by === "" ? "-" : template.created_by?.length > 30 ? template.created_by.substring(0, 30) + "..."
                        : template.created_by}</td>
                    <td>{template.created_on ? moment(template.created_on).format('MM/DD/YYYY') : "-"}</td>
                    <td title={template.last_modified_by?.length > 30 ? template.last_modified_by : ""}>{template.last_modified_by === "" ? "-" : template.last_modified_by?.length > 30 ? template.last_modified_by.substring(0, 30) + "..."
                        : template.last_modified_by}</td>
                    <td>{(template.last_modified_on === "" || template.last_modified_on === "0001-01-01T00:00:00Z") ? "-" : moment(template.last_modified_on).format('MM/DD/YYYY')}</td>
                    <td className="text-center">
                        <div className="d-flex align-items-center justify-content-center gap-2">
                            <button type="button" className="btn p-0 action-btn" onClick={() => { setState("Edit"); getBreakTemplateByuuid(template.break_template_uuid); setShowCreateEditPopup(true) }}>
                                <img src="img/edit-icon.svg" alt="edit-icon-blue" />
                            </button>
                            <button type="button" className="btn p-0 action-btn" onClick={() => { setDeleteBreakTemplateID(template.break_template_uuid); getAssociatedTeamsWithBreakTemplate(template.break_template_uuid) }}>
                                <img src="img/delete-icon.svg" alt="bin-icon-red" />
                            </button>
                        </div>
                    </td>
                </tr>)
        }))
    }

    const breakTimingsBinding = (break_timings: any) => {
        if (!break_timings || break_timings?.length === 0) return null;
        break_timings = break_timings.sort((a: any, b: any) => {
            // Extract hours and minutes from the break_start_time string
            const [hoursA, minutesA] = a.break_start_time.includes('T') ? a.break_start_time.split('T')[1].split(':').map(Number) : a.break_start_time.split(':').map(Number);
            const [hoursB, minutesB] = b.break_start_time.includes('T') ? b.break_start_time.split('T')[1].split(':').map(Number) : b.break_start_time.split(':').map(Number);

            // Compare hours
            if (hoursA !== hoursB) {
                return hoursA - hoursB;
            }
            // If hours are equal, compare minutes
            else {
                return minutesA - minutesB;
            }
        })
        const formattedTimings = break_timings.map((range: any) => formatStartTime(range.break_start_time));
        const visibleTimings = formattedTimings.slice(0, 3);
        const hiddenTimings = formattedTimings.slice(3);

        return (
            <>
                {visibleTimings.map((time: string, index: number) => (
                    <span key={index} className="gradient-tag me-1">{time}</span>
                ))}
                {hiddenTimings.length > 0 && (
                    <span className="gradient-tag me-1 cursor-pointer" title={hiddenTimings.join(', ')}>+{hiddenTimings.length}</span>
                )}
            </>
        );
    };

    // PS_BT_27: handleSearch function
    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newSearchValue = event.target.value;
        setSearchTerm(newSearchValue);
    };

    const handleApplySearch = () => {
        setGetRequestPayload((prevPayload: GetRequestPayload) => ({
            ...prevPayload,
            search_value: searchTerm,
            page_number: 1,
            records_per_page: 10,
        }));
    }
    const handleSort = (sort_column: string, sort_order: string) => {
        setHideSort(`${sort_column}@${sort_order}`);
        setGetRequestPayload((prevPayload: GetRequestPayload) => ({
            ...prevPayload,
            sort_column: sort_column,
            sort_order: sort_order
        }));
    }

    const handleDateRangeSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDateRange((prev: GetRequestPayload) => ({
            ...prev,
            [event.target.name]: event.target.value
        }))
    }

    const handleDateApply = () => {
        setGetRequestPayload((prev: GetRequestPayload) => ({
            ...prev,
            created_date_from: dateRange.created_date_from,
            created_date_to: dateRange.created_date_to,
            modified_date_from: dateRange.modified_date_from,
            modified_date_to: dateRange.modified_date_to,
            page_number: 1,
            records_per_page: 10
        }))
    }

    const handleClearFilter = () => {
        setDateRange((prev: GetRequestPayload) => ({
            ...prev,
            created_date_from: "",
            created_date_to: "",
            modified_date_from: "",
            modified_date_to: ""
        }))
        setGetRequestPayload((prev: GetRequestPayload) => ({
            ...prev,
            created_date_from: "",
            created_date_to: "",
            modified_date_from: "",
            modified_date_to: "",
            page_number: 1,
            records_per_page: 10
        }))
    }

    const isValidDate = (date: any): boolean => {
        return date !== undefined && date !== null && date !== '';
    };
    const formatStartTime = (timeValue: string) => {
        if (timeValue.includes('T')) {
            // Extract the time part from the ISO string
            const timePart = timeValue.split('T')[1].split('.')[0];
            const [hours, minutes] = timePart.split(':');
            // Pad with leading zeros if necessary
            const formattedHours = hours.padStart(2, '0');
            const formattedMinutes = minutes.padStart(2, '0');
            return `${formattedHours}h ${formattedMinutes}m`;
        } else {
            // If it's already in HH:MM format, just return it
            const [hours, minutes] = timeValue.split(':');
            return `${hours.padStart(2, '0')}h ${minutes.padStart(2, '0')}m`;
        }
    }

    const requestBreakTimingsBinding = () => {
        return (state === "Create" ? createBreakTemplateRequest?.break_timings?.map((range: any, index: number) => {
            // Split the time string into its components
            var formattedTime = formatStartTime(range.break_start_time)
            return (
                editIndex !== index ? <tr>
                    <td>{formattedTime}</td>
                    <td>{range.duration_minutes}m</td>
                    <td className="text-center">
                        <div className="d-flex align-items-center justify-content-center gap-2">
                            <button type="button" className="action-btn btn p-0" onClick={() => { setEditIndex(index); setEditBreak(range) }}>
                                <img src="img/edit-icon.svg" alt="edit-icon-blue" />
                            </button>

                            <button type="button" className="action-btn btn p-0" onClick={() => deleteBreakTiming(index)}>
                                <img src="img/delete-icon.svg" alt="bin-icon-red" />
                            </button>
                        </div>
                    </td>
                </tr> :
                    <tr><td>
                        <input type="time"
                            className="form-control custom-input-w-155 font-12 theme-input shadow-none"
                            value={editBreak?.break_start_time} name="break_start_time"
                            onChange={(event) => setEditBreak((prev: CreateBreakTimingRequest) => ({ ...prev, break_start_time: event.target.value.length === 5 ? `${event.target.value}:00` : event.target.value }))} />
                    </td>
                        <td>
                            <select className="form-select custom-input-w-155"
                                aria-label="Default select example" name="duration_minutes" value={editBreak?.duration_minutes}
                                onChange={(event: any) => { setEditBreak((prev: CreateBreakTimingRequest) => ({ ...prev, duration_minutes: event.target.value })) }}>
                                <option value={0} selected>Select</option>
                                <option value={5}>5m</option>
                                <option value={10}>10m</option>
                                <option value={15}>15m</option>
                                <option value={20}>20m</option>
                                <option value={25}>25m</option>
                                <option value={30}>30m</option>
                                <option value={35}>35m</option>
                                <option value={40}>40m</option>
                                <option value={45}>45m</option>
                                <option value={50}>50m</option>
                                <option value={55}>55m</option>
                                <option value={60}>60m</option>
                            </select>
                        </td>
                        <td className="text-center">
                            <div className="d-flex align-items-center justify-content-center gap-2">
                                <button type="button" className="action-btn btn p-0" onClick={() => { handleBreakTimingChange(index, editBreak); setEditIndex(null) }}>
                                    <img src="img/tick-icon-green.svg" alt="edit-icon-blue" />
                                </button>

                                <button type="button" className="action-btn btn p-0" onClick={() => { setEditBreak({ break_start_time: "", duration_minutes: 0 }); setEditIndex(null) }}>
                                    <img src="img/cancel-icon.svg" alt="bin-icon-red" />
                                </button>
                            </div>
                        </td>
                    </tr>
            )
        }) : updateBreakTemplateRequest?.break_timings?.map((range: UpdateBreakTimingRequest, index: number) => {
            var formattedTime = formatStartTime(range.break_start_time)
            return (
                editIndex !== index ? <tr>
                    <td>{formattedTime}</td>
                    <td>{range.duration_minutes}m</td>
                    <td className="text-center">
                        <div className="d-flex align-items-center justify-content-center gap-2">
                            <button type="button" className="action-btn btn p-0" onClick={() => {
                                setEditIndex(index); setEditBreak({
                                    break_start_time: range.break_start_time,
                                    duration_minutes: range.duration_minutes
                                })
                            }}>
                                <img src="img/edit-icon.svg" alt="edit-icon-blue" />
                            </button>

                            <button type="button" className="action-btn btn p-0" onClick={() => deleteBreakTiming(index)}>
                                <img src="img/delete-icon.svg" alt="bin-icon-red" />
                            </button>
                        </div>
                    </td>
                </tr> : <tr><td>
                    <input type="time"
                        className="form-control custom-input-w-155 font-12 theme-input shadow-none"
                        value={getBreakTimingValue(editBreak?.break_start_time)} name="break_start_time"
                        onChange={(event) => setEditBreak((prev: CreateBreakTimingRequest) => ({ ...prev, break_start_time: event.target.value.length === 5 ? `${event.target.value}:00` : event.target.value }))} />
                </td>
                    <td><select className="form-select custom-input-w-155"
                        aria-label="Default select example" name="duration_minutes" value={editBreak?.duration_minutes}
                        onChange={(event: any) => { setEditBreak((prev: CreateBreakTimingRequest) => ({ ...prev, duration_minutes: Number(event.target.value) })) }}>
                        <option value={0} selected>Select</option>
                        <option value={5}>5m</option>
                        <option value={10}>10m</option>
                        <option value={15}>15m</option>
                        <option value={20}>20m</option>
                        <option value={25}>25m</option>
                        <option value={30}>30m</option>
                        <option value={35}>35m</option>
                        <option value={40}>40m</option>
                        <option value={45}>45m</option>
                        <option value={50}>50m</option>
                        <option value={55}>55m</option>
                        <option value={60}>60m</option>
                    </select>
                    </td>
                    <td className="text-center">
                        <div className="d-flex align-items-center justify-content-center gap-2">
                            <button type="button" className="action-btn btn p-0" onClick={() => { handleBreakTimingChange(index, editBreak); setEditIndex(null) }}>
                                <img src="img/tick-icon-green.svg" alt="edit-icon-blue" />
                            </button>

                            <button type="button" className="action-btn btn p-0" onClick={() => { setEditBreak({ break_start_time: "", duration_minutes: 0 }); setEditIndex(null) }}>
                                <img src="img/cancel-icon.svg" alt="bin-icon-red" />
                            </button>
                        </div>
                    </td>
                </tr>
            )
        }))
    }

    return (
        <>
            <div className="container-fluid">
                <div className="p-3">
                    {/* Breadcrumbs */}
                    <nav aria-label="breadcrumb" className="theme-breadcrumbs">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <a >Admin</a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                Break Templates
                            </li>
                        </ol>
                    </nav>
                    <div className="d-lg-flex align-items-center justify-content-between">
                        <ul className="nav nav-pills theme-tab" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link"
                                    id="pills-images-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-images"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-images"
                                    aria-selected="false"
                                    tabIndex={-1}
                                >
                                    Shift Templates
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link active"
                                    id="pills-liveFeed-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-liveFeed"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-liveFeed"
                                    aria-selected="true"
                                >
                                    Break Templates
                                </button>
                            </li>
                        </ul>
                        <div className="d-flex align-items-center gap-3 mt-3 mt-lg-0">
                            <div className="input-group custom-input search-grp rounded-2 overflow-hidden">
                                <input
                                    type="search"
                                    className="form-control font-semibold border-0 shadow-none font-14"
                                    placeholder="Search"
                                    aria-label="Search"
                                    value={searchTerm}
                                    onChange={(event) => handleSearch(event)}
                                    onKeyUp={(event) => event.key === "Enter" ? handleApplySearch() : null}
                                    max={255}
                                />
                                <span className="input-group-text border-0 bg-transparent" onClick={() => handleApplySearch()}>
                                    <img src="img/search-icon.svg" alt="search" />
                                </span>
                            </div>
                            <div className="dropdown filter-dpd">
                                <button
                                    className="shadow-btn rounded-2 d-flex align-items-center justify-content-center gap-2 font-semibold font-14 color-tinted-grey lh-1 px-3"
                                    type="button"
                                    onClick={() => setShowDateFilter(!showDateFilter)}
                                >
                                    <img
                                        src="img/filter-icon-blue.svg"
                                        alt="filter"
                                        className="filter-img"
                                    />
                                    <span>Filter</span>
                                </button>
                                {showDateFilter && <div
                                    className="dropdown-menu show active border-0 custom-shadow dropdown-menu-end filter-dpd p-3 mt-2"
                                    style={{ zIndex: 99999, position: "absolute", right: "0%" }}
                                >
                                    <div className="d-flex align-items-center justify-content-between mb-3">
                                        <h3 className="m-0 font-bold font-16 color-black-v1">
                                            Advanced Filter
                                        </h3>
                                        <button className="bg-transparent border-0" type="button" onClick={() => setShowDateFilter(false)}>
                                            <img src="img/close-icon.svg" alt="close" />
                                        </button>
                                    </div>
                                    <h4 className="mb-3 font-semibold font-14 color-black-v1">
                                        Created Date Range
                                    </h4>
                                    <div className="row g-3">
                                        <div className="col-6">
                                            <label
                                                htmlFor="from_date"
                                                className="font-semibold font-12 color-black-v1 form-label"
                                            >
                                                From
                                            </label>
                                            <input
                                                type="date"
                                                id="from_date"
                                                name="created_date_from"
                                                className="form-control font-12 theme-input shadow-none"
                                                value={dateRange.created_date_from}
                                                max={dateRange.created_date_to}
                                                onKeyDown={(e) => e.preventDefault()}
                                                onChange={(event) => handleDateRangeSelection(event)}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <label
                                                htmlFor="to_date"
                                                className="font-semibold font-12 color-black-v1 form-label"
                                            >
                                                To
                                            </label>
                                            <input
                                                type="date"
                                                id="to_date"
                                                name="created_date_to"
                                                className="form-control font-12 theme-input shadow-none"
                                                value={dateRange.created_date_to}
                                                min={dateRange.created_date_from}
                                                onKeyDown={(e) => e.preventDefault()}
                                                onChange={(event) => handleDateRangeSelection(event)}
                                            />
                                        </div>
                                    </div>
                                    <h4 className="mb-3 font-semibold font-14 color-black-v1 mt-4">
                                        Modified Date Range
                                    </h4>
                                    <div className="row g-3">
                                        <div className="col-6">
                                            <label
                                                htmlFor="from_date_md"
                                                className="font-semibold font-12 color-black-v1 form-label"
                                            >
                                                From
                                            </label>
                                            <input
                                                type="date"
                                                id="from_date_md"
                                                name="modified_date_from"
                                                className="form-control font-12 theme-input shadow-none"
                                                value={dateRange.modified_date_from}
                                                max={dateRange.modified_date_to}
                                                onKeyDown={(e) => e.preventDefault()}
                                                onChange={(event) => handleDateRangeSelection(event)}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <label
                                                htmlFor="to_date_md"
                                                className="font-semibold font-12 color-black-v1 form-label"
                                            >
                                                To
                                            </label>
                                            <input
                                                type="date"
                                                id="to_date_md"
                                                name="modified_date_to"
                                                className="form-control font-12 theme-input shadow-none"
                                                value={dateRange.modified_date_to}
                                                min={dateRange.modified_date_from}
                                                onKeyDown={(e) => e.preventDefault()}
                                                onChange={(event) => handleDateRangeSelection(event)}
                                            />
                                        </div>
                                        <div className="co1-12">
                                            <div className="d-flex justify-content-end gap-3 mt-4">
                                                <button className="secondary-btn rounded-3" type="button" onClick={() => { handleClearFilter() }}>
                                                    <span className="d-inline-block my-1">Cancel</span>
                                                </button>
                                                <button
                                                    className="primary-btn rounded-3"
                                                    type="button"
                                                    disabled={
                                                        !(isValidDate(dateRange.modified_date_to) ||
                                                            isValidDate(dateRange.modified_date_from) ||
                                                            isValidDate(dateRange.created_date_from) ||
                                                            isValidDate(dateRange.created_date_to)) ||
                                                        (isValidDate(dateRange.created_date_from) && !isValidDate(dateRange.created_date_to)) ||
                                                        (isValidDate(dateRange.created_date_to) && !isValidDate(dateRange.created_date_from)) ||
                                                        (isValidDate(dateRange.modified_date_from) && !isValidDate(dateRange.modified_date_to)) ||
                                                        (isValidDate(dateRange.modified_date_to) && !isValidDate(dateRange.modified_date_from))
                                                    } onClick={() => { handleDateApply(); setShowDateFilter(false) }}
                                                >
                                                    <span className="d-inline-block my-1">Apply</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                            </div>
                            <button
                                className="border-0 snooze-btn active font-semibold font-12 text-nowrap"
                                type="button"
                                onClick={() => { setState("Create"); setShowCreateEditPopup(true) }}
                            >
                                Add Break Template
                            </button>
                        </div>
                    </div>
                    <div className="tab-content" id="pills-tabContent">
                        <div
                            className="tab-pane fade show active"
                            id="pills-liveFeed"
                            role="tabpanel"
                            aria-labelledby="pills-liveFeed-tab"
                            tabIndex={0}
                        >
                            <div className="table-responsive theme-table bg-white w-100 rounded-3 mt-4 custom-spacing text-nowrap">
                                <table className="table mb-0">
                                    <thead>
                                        <tr>
                                            <th className={breakTemplates && breakTemplates?.length > 1 ? "cursor-pointer" : ""} onClick={() => {
                                                if (breakTemplates && breakTemplates.length > 1) {
                                                    hideSort === "break_name@asc" ? handleSort("break_name", "desc") : handleSort("break_name", "asc")
                                                }
                                            }}>
                                                Break Name
                                                {breakTemplates && breakTemplates?.length > 1 ? <> <span className="cursor-pointer">
                                                    <img src="img/sort-up-arrow.svg" alt="sort-arrow-down"
                                                        className="sort_created_on"
                                                        onClick={() => handleSort("break_name", "desc")}
                                                        hidden={hideSort === "break_name@asc" ? false : true}
                                                    />
                                                </span>
                                                    <span className="cursor-pointer">

                                                        <img src="img/sort-down-arrow.svg" alt="sort-down-up" className="sort_created_on" onClick={() => handleSort("break_name", "asc")}
                                                            hidden={hideSort === "break_name@asc" ? true : false} />
                                                    </span>
                                                </>
                                                    : null}
                                            </th>
                                            <th>
                                                Break Ranges{" "}
                                                <span className="cursor-pointer position-relative tooltip-icon">
                                                    <img src="img/tooltip-icon.svg" alt="sort-arrow-down" style={{ marginBottom: "2px" }} />
                                                    <span className="custom-tooltip">
                                                        <span className="color-white font-9 font-regular">
                                                            The column defines the break ranges
                                                        </span>
                                                    </span>
                                                </span>
                                            </th>
                                            <th className={breakTemplates && breakTemplates?.length > 1 ? "cursor-pointer" : ""} onClick={() => {
                                                if (breakTemplates && breakTemplates.length > 1) {
                                                    hideSort === "created_by@asc" ? handleSort("created_by", "desc") : handleSort("created_by", "asc")
                                                }
                                            }}>
                                                Created By
                                                {breakTemplates && breakTemplates?.length > 1 ? <><span className="cursor-pointer">
                                                    <img src="img/sort-up-arrow.svg" alt="sort-arrow-down"
                                                        id="created_by@asc"
                                                        className="sort-spacing"
                                                        onClick={() => handleSort("created_by", "desc")}
                                                        hidden={hideSort === "created_by@asc" ? false : true}
                                                    />
                                                </span>

                                                    <span className="cursor-pointer">
                                                        <img src="img/sort-down-arrow.svg" alt="sort-arrow-up" onClick={() => handleSort("created_by", "asc")}
                                                            id="created_by@desc"
                                                            className="sort-spacing"
                                                            hidden={hideSort === "created_by@asc" ? true : false} />
                                                    </span> </> : null}
                                            </th>
                                            <th className={breakTemplates && breakTemplates?.length > 1 ? "cursor-pointer" : ""} onClick={() => {
                                                if (breakTemplates && breakTemplates.length > 1) {
                                                    hideSort === "created_on@asc" ? handleSort("created_on", "desc") : handleSort("created_on", "asc")
                                                }
                                            }}>
                                                Created On
                                                {breakTemplates && breakTemplates?.length > 1 ? <>  <span className="cursor-pointer">
                                                    <img src="img/sort-up-arrow.svg" alt="sort-arrow-down"
                                                        id="created_on@asc"
                                                        className="sort_created_on"
                                                        onClick={() => handleSort("created_on", "desc")}
                                                        hidden={hideSort === "created_on@asc" ? false : true}
                                                    />
                                                </span>

                                                    <span className="cursor-pointer">
                                                        <img src="img/sort-down-arrow.svg" alt="sort-arrow-up" onClick={() => handleSort("created_on", "asc")}
                                                            id="created_on@desc"
                                                            className="sort_created_on"
                                                            hidden={hideSort === "created_on@asc" ? true : false} />
                                                    </span>
                                                </>
                                                    : null}
                                            </th>
                                            <th className={breakTemplates && breakTemplates?.length > 1 ? "cursor-pointer" : ""} onClick={() => {
                                                if (breakTemplates && breakTemplates.length > 1) {
                                                    hideSort === "modified_by@asc" ? handleSort("modified_by", "desc") : handleSort("modified_by", "asc")
                                                }
                                            }}>
                                                Last Modified By
                                                {breakTemplates && breakTemplates?.length > 1 ? <> <span className="cursor-pointer">
                                                    <img src="img/sort-up-arrow.svg" alt="sort-arrow-down"
                                                        id="modifiedBy@asc"
                                                        className="sort_created_on"
                                                        onClick={() => handleSort("modified_by", "desc")}
                                                        hidden={hideSort === "modified_by@asc" ? false : true}
                                                    />
                                                </span>

                                                    <span className="cursor-pointer">
                                                        <img src="img/sort-down-arrow.svg" alt="sort-arrow-up" onClick={() => handleSort("modified_by", "asc")}
                                                            id="modifiedBy@desc"
                                                            className="sort_created_on"
                                                            hidden={hideSort === "modified_by@asc" ? true : false} />
                                                    </span>
                                                </> : null}
                                            </th>
                                            <th className={breakTemplates && breakTemplates?.length > 1 ? "cursor-pointer" : ""} onClick={() => {
                                                if (breakTemplates && breakTemplates.length > 1) {
                                                    hideSort === "modified_on@asc" ? handleSort("modified_on", "desc") : handleSort("modified_on", "asc")
                                                }
                                            }}>
                                                Last Modified On
                                                {breakTemplates && breakTemplates?.length > 1 ? <> <span className="cursor-pointer">
                                                    <img src="img/sort-up-arrow.svg" alt="sort-arrow-down"
                                                        id="shiftaName@asc"
                                                        className="sort_created_on"
                                                        onClick={() => handleSort("modified_on", "desc")}
                                                        hidden={hideSort === "modified_on@asc" ? false : true}
                                                    />
                                                </span>

                                                    <span className="cursor-pointer">
                                                        <img src="img/sort-down-arrow.svg" alt="sort-arrow-up" className="sort_created_on" onClick={() => handleSort("modified_on", "asc")}
                                                            hidden={hideSort === "modified_on@asc" ? true : false} />
                                                    </span></> : null}
                                            </th>
                                            <th className="text-center">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {breakTemplates && breakTemplates?.length > 0 ? breakTemplatesBinding() :
                                            <NoRecordsFoundComponent colspan={7} mainContent={"No Templates Found"} subContent={"Templates will appear after they are created"} columnHeight={"50"}></NoRecordsFoundComponent>}
                                    </tbody>
                                </table>
                            </div>
                            {(breakTemplates && breakTemplates?.length > 0) && <div className="mt-1"> <PaginationComponent totalCount={metaData?.total}
                                initialRecordsPerPage={metaData?.page_size} currentPage={metaData.page} setPageAndRecords={(currentPage: number, totalCount: number) => {
                                    setGetRequestPayload((prev: GetRequestPayload) => ({
                                        ...prev, page_number: currentPage, records_per_page: totalCount
                                    }))
                                }}></PaginationComponent></div>}
                        </div>
                    </div>
                </div>
            </div>

            {showCreateEditPopup && <div className="modal fade show custom-popup" id="staticBackdrop-2"
                style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)", backdropFilter: "blur(1px)" }} data-bs-backdrop="static" data-bs-keyboard="false"
                tabIndex={-1} aria-labelledby="staticBackdropLabel-2" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title font-16 font-bold color-black" id="staticBackdropLabel-2">Break Template</h1>
                            <button type="button" className="btn-close" aria-label="Close"
                                onClick={() => {
                                    setCreateBreakTemplateRequest({ break_name: "", break_timings: [] }); setCreateBreakTiming({ break_start_time: "", duration_minutes: 0 });
                                    setShowCreateEditPopup(false); setErrorMessage(""); setEditIndex(null)
                                }}>
                            </button>
                        </div>
                        <div className="modal-body custom-popup-form">
                            <div className="row justify-content-between">
                                <div className="col-md-6 field-size">
                                    <label htmlFor="break-name" className="m-0 font-semibold font-13 color-black-v1 mb-2">Break Name
                                        <span className="color-red">*</span></label>
                                    <div className="input-group rounded-2 overflow-hidden input-grp-border">
                                        <input id="break-name" value={state === "Create" ? createBreakTemplateRequest.break_name : updateBreakTemplateRequest?.break_name} type="text" className="form-control font-12 shadow-none border-0"
                                            name="break_name" placeholder="Enter Break Name" maxLength={255} onChange={(event) => { onChangeDataSetter(event); setErrorMessage("") }} />
                                    </div>
                                </div>
                                <div className="error text-danger font-13">{errorMessage}</div>
                            </div>

                            <h2 className="font-14 font-bold color-black mt-4 mb-3">Break Timings</h2>
                            <div className="table-responsive theme-table bg-white w-100 rounded-3 mt-2 custom-spacing">
                                <table className="table mb-0">
                                    <thead className="sticky-top">
                                        <tr>
                                            <th className={(updateBreakTemplateRequest?.break_timings?.length > 1 || createBreakTemplateRequest?.break_timings?.length > 1) ? "cursor-pointer" : ""} onClick={() => {
                                                if (startTimeSort === "asc") {
                                                    state === "Create" ? sortBreakTimingsByStartTime(createBreakTemplateRequest, "desc")
                                                        : sortBreakTimingsByStartTime(updateBreakTemplateRequest, "desc");
                                                }
                                                else {
                                                    state === "Create" ? sortBreakTimingsByStartTime(createBreakTemplateRequest, "asc")
                                                        : sortBreakTimingsByStartTime(updateBreakTemplateRequest, "asc");
                                                }
                                            }}>
                                                Time from Shift Start
                                                {(updateBreakTemplateRequest?.break_timings?.length > 1 || createBreakTemplateRequest?.break_timings?.length > 1) && (
                                                    startTimeSort === "asc" ? (
                                                        <span className="cursor-pointer" onClick={() => {
                                                            state === "Create" ? sortBreakTimingsByStartTime(createBreakTemplateRequest, "desc")
                                                                : sortBreakTimingsByStartTime(updateBreakTemplateRequest, "desc");
                                                        }}>
                                                            <img src="img/sort-up-arrow.svg" alt="sort-arrow-down" className="ms-1" />
                                                        </span>
                                                    ) : (
                                                        <span className="cursor-pointer" onClick={() => {
                                                            state === "Create" ? sortBreakTimingsByStartTime(createBreakTemplateRequest, "asc")
                                                                : sortBreakTimingsByStartTime(updateBreakTemplateRequest, "asc");
                                                        }}>
                                                            <img src="img/sort-down-arrow.svg" alt="sort-arrow-up" className="ms-1" />
                                                        </span>
                                                    )
                                                )}
                                            </th>
                                            <th className={(updateBreakTemplateRequest?.break_timings?.length > 1 || createBreakTemplateRequest?.break_timings?.length > 1) ? "cursor-pointer" : ""} onClick={() => {
                                                if (durationSort === "asc") {
                                                    state === "Create" ? sortBreakTimingsByDuration(createBreakTemplateRequest, "desc")
                                                        : sortBreakTimingsByDuration(updateBreakTemplateRequest, "desc");
                                                }
                                                else {
                                                    state === "Create" ? sortBreakTimingsByDuration(createBreakTemplateRequest, "asc")
                                                        : sortBreakTimingsByDuration(updateBreakTemplateRequest, "asc");
                                                }
                                            }}>
                                                Duration
                                                {(updateBreakTemplateRequest?.break_timings?.length > 1 || createBreakTemplateRequest?.break_timings?.length > 1) && (
                                                    durationSort === "asc" ? (
                                                        <span className="cursor-pointer" onClick={() => {
                                                            state === "Create" ? sortBreakTimingsByDuration(createBreakTemplateRequest, "desc")
                                                                : sortBreakTimingsByDuration(updateBreakTemplateRequest, "desc");
                                                        }}>
                                                            <img src="img/sort-up-arrow.svg" alt="sort-arrow-down" className="ms-1" />
                                                        </span>
                                                    ) : (
                                                        <span className="cursor-pointer" onClick={() => {
                                                            state === "Create" ? sortBreakTimingsByDuration(createBreakTemplateRequest, "asc")
                                                                : sortBreakTimingsByDuration(updateBreakTemplateRequest, "asc");
                                                        }}>
                                                            <img src="img/sort-down-arrow.svg" alt="sort-arrow-up" className="ms-1" />
                                                        </span>
                                                    )
                                                )}
                                            </th>
                                            <th className="text-center">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr><td>
                                            <input type="time"
                                                className="form-control custom-input-w-155 font-12 theme-input shadow-none" value={createBreakTiming.break_start_time.substring(0, 5)}
                                                name="break_start_time" onChange={(event) => onChangeTimingRequestData(event)} />
                                        </td>
                                            <td><select className="form-select custom-input-w-155"
                                                aria-label="Default select example" name="duration_minutes" value={createBreakTiming.duration_minutes} onChange={(event) => onChangeTimingRequestData(event)} style={{
                                                    color: selectedOption ? 'black' : '#757575'
                                                }}>
                                                <option value={0} selected disabled>Select</option>
                                                <option value={5}>5m</option>
                                                <option value={10}>10m</option>
                                                <option value={15}>15m</option>
                                                <option value={20}>20m</option>
                                                <option value={25}>25m</option>
                                                <option value={30}>30m</option>
                                                <option value={35}>35m</option>
                                                <option value={40}>40m</option>
                                                <option value={45}>45m</option>
                                                <option value={50}>50m</option>
                                                <option value={55}>55m</option>
                                                <option value={60}>60m</option>
                                            </select>
                                            </td>
                                            <td className="text-center">
                                                <div className="d-flex align-items-center justify-content-center gap-2">
                                                    <button type="button" style={{ border: "0px" }} className="action-btn btn p-0"
                                                        disabled={createBreakTiming.break_start_time === "" || createBreakTiming.duration_minutes === 0} onClick={() => addBreakTiming()}>
                                                        <img src="img/plus-icon-green.svg" alt="bin-icon-red" />
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                        {requestBreakTimingsBinding()}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="col-md-12">
                                <div className="d-flex justify-content-end gap-3 mt-2 mb-3">
                                    <button className="secondary-btn rounded-3" type="button" onClick={() => {
                                        setCreateBreakTemplateRequest({ break_name: "", break_timings: [] });
                                        setCreateBreakTiming({ break_start_time: "", duration_minutes: 0 }); setShowCreateEditPopup(false); setErrorMessage(""); setEditIndex(null)
                                    }}><span
                                        className="d-inline-block my-1">Cancel</span></button>
                                    <button disabled={!(state === "Create" ? (createBreakTemplateRequest.break_name && createBreakTemplateRequest.break_timings.length > 0)
                                        : (updateBreakTemplateRequest.break_name && updateBreakTemplateRequest.break_timings?.length > 0 && isButtonDisabled))} className="primary-btn rounded-3" type="button" onClick={() => {
                                            if (state === "Create") {
                                                createBreakTemplate();
                                                setCreateBreakTiming({ break_start_time: "", duration_minutes: 0 })
                                                setEditIndex(null)
                                            }
                                            else {
                                                updateBreakTemplate();
                                                setCreateBreakTiming({ break_start_time: "", duration_minutes: 0 })
                                                setEditIndex(null)
                                            }
                                        }}><span
                                            className="d-inline-block my-1" >{state === "Create" ? "Add" : "Update"}</span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}

            {showAssociatedTeamsAlertPopup && <><div className="container-fluid position-fixed toastContainer warning-toast">
                <div className="p-4 pb-3 toast-content d-flex align-items-start gap-3">
                    <img src="img/warning-icon-lg.svg" alt="warning" />
                    <div className="d-flex flex-column gap-4 mt-2">
                        <h3 className="font-bold font-16 color-white m-0">This break is assigned to {associatedTeams} teams, so deletion cannot be performed right now</h3>
                        <div className="action-btns d-flex gap-2">
                            <button type="button" className="action-btn-yes font-10 font-semibold px-3 py-2 rounded-2" onClick={() => setShowAssociatesTeamsAlertPopup(false)}>OK</button>
                        </div>
                    </div>
                </div>
            </div></>}

            {showConfirmDeletion && (
                <DeletionConfirmationPopup
                    message="Are you sure you want to delete this break template?"
                    onConfirmDelete={deleteBreakTemplate}
                    onCancelDelete={cancelDeleteBreakTemplate}
                />
            )}

            {showToast && (
                <ToastMessage props={{
                    isToast: showToast,
                    setIsToast: setShowToast,
                    toastMessage: toastMessage,
                    toastType: toastType,
                }} />
            )}
        </>
    )
}