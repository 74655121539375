import moment from "moment";
import { useEffect, useState, useCallback, useRef } from "react";

export function ShiftScroll(shiftData: any) {
  const current_time = new Date().toTimeString().split(' ')[0]; // Current time
  const shiftDetails = shiftData?.shiftData;

  const [hours, setHours] = useState<JSX.Element[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isScrolledToStart, setIsScrolledToStart] = useState(true);
  const [isScrolledToEnd, setIsScrolledToEnd] = useState(false);

  const formatTimeRange = useCallback((shiftStartTime: string, breakStartTime: string, durationMinutes: string): string => {
    const [shiftHours, shiftMinutes] = shiftStartTime.split(':').map(Number);
    const [breakHours, breakMinutes] = breakStartTime.split(':').map(Number);

    let startHours = shiftHours + breakHours;
    let startMinutes = shiftMinutes + breakMinutes;

    if (startMinutes >= 60) {
      startHours += Math.floor(startMinutes / 60);
      startMinutes %= 60;
    }

    let endHours = startHours;
    let endMinutes = startMinutes + parseInt(durationMinutes, 10);

    if (endMinutes >= 60) {
      endHours += Math.floor(endMinutes / 60);
      endMinutes %= 60;
    }

    startHours %= 24;
    endHours %= 24;

    const startPeriod = startHours >= 12 ? 'PM' : 'AM';
    const endPeriod = endHours >= 12 ? 'PM' : 'AM';

    const startHours12 = startHours % 12 || 12;
    const endHours12 = endHours % 12 || 12;

    const formattedStart = `${startHours12.toString().padStart(2, '0')}:${startMinutes.toString().padStart(2, '0')}`;
    const formattedEnd = `${endHours12.toString().padStart(2, '0')}:${endMinutes.toString().padStart(2, '0')}`;

    return `${formattedStart} - ${formattedEnd} ${startPeriod === endPeriod ? startPeriod : `${startPeriod}-${endPeriod}`}`;
  }, []);

  const getBreakElement = useCallback((hourTime: moment.Moment): JSX.Element | null => {
    const shiftStart = moment(shiftDetails?.shift_start_time, 'HH:mm:ss');

    for (const breakRange of shiftDetails?.break_ranges) {
      const breakStart = moment(breakRange?.break_start_time, 'HH:mm:ss');
      const actualBreakStart = shiftStart.clone().add(breakStart.hour(), 'hours').add(breakStart.minute(), 'minutes');
      const durationMinutes = parseInt(breakRange?.duration_minutes, 10);
      const actualBreakEnd = actualBreakStart.clone().add(durationMinutes, 'minutes');

      if (hourTime.isBetween(actualBreakStart, actualBreakEnd, 'hour', '[]')) {
        let startMinute = 0;
        let endMinute = 60;
        let widthPercentage = 100;
        let leftPercentage = 0;

        if (hourTime.isSame(actualBreakStart, 'hour')) {
          startMinute = actualBreakStart.minute();
          leftPercentage = (startMinute / 60) * 100;
        }

        if (hourTime.isSame(actualBreakEnd, 'hour')) {
          endMinute = actualBreakEnd.minute();
        }

        widthPercentage = ((endMinute - startMinute) / 60) * 100;

        const breakStyle: React.CSSProperties = {
          width: `${widthPercentage}%`,
          height: '36px',
          backgroundColor: '#FDA700',
          position: 'absolute',
          left: `${leftPercentage}%`,
        };

        return (
          <span
            className="break lg cursor-pointer tooltip-icon"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            style={breakStyle}
          >
            <span className="custom-tooltip">
              <span className="color-white font-9 font-regular">
                {formatTimeRange(shiftDetails?.shift_start_time, breakRange?.break_start_time, breakRange?.duration_minutes)}
              </span>
            </span>
          </span>
        );
      }
    }

    return null;
  }, [shiftDetails, formatTimeRange]);

 
  const generateHours = useCallback(() => {
    const startTime = moment(shiftDetails?.shift_start_time, 'HH:mm:ss');
    const endTime = moment(shiftDetails?.shift_end_time, 'HH:mm:ss');
    const currentTime = moment(current_time, 'HH:mm:ss');

    const hourElements: JSX.Element[] = [];

    for (let i = 0; i < 24; i++) {
      const hourTime = moment().startOf('day').add(i, 'hours');
      const isShift = hourTime.isBetween(startTime, endTime, 'hour', '[]');
      const isCurrent = hourTime.isSame(currentTime, 'hour');

      let className = `hour lg hour-${i}`;
      if (isShift) className += ' shift';
      if (isCurrent) className += ' current-time';

      const breakElement = getBreakElement(hourTime);

      hourElements.push(
        <div key={i} className={className} id={`hour-${i}`}>
          {breakElement}
        </div>
      );
    }

    setHours(hourElements);
  }, [shiftDetails, current_time, getBreakElement]);

  useEffect(() => {
    generateHours();
  }, [generateHours]);

  const scrollContainer = (direction: 'left' | 'right') => {
    if (containerRef.current) {
      const scrollAmount = 100; // Adjust this value to control scroll distance
      const newScrollLeft = containerRef.current.scrollLeft + (direction === 'left' ? -scrollAmount : scrollAmount);
      containerRef.current.scrollTo({
        left: newScrollLeft,
        behavior: 'smooth'
      });
    }
  }; 

  const updateScrollButtons = useCallback(() => {
    if (containerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
      setIsScrolledToStart(scrollLeft <= 0);
      setIsScrolledToEnd(scrollLeft >= scrollWidth - clientWidth - 1); // Subtract 1 to account for potential rounding errors
    }
  }, []);

  const checkInitialScroll = useCallback(() => {
    if (containerRef.current) {
      const { scrollWidth, clientWidth } = containerRef.current;
      setIsScrolledToEnd(scrollWidth <= clientWidth);
    }
  }, []);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', updateScrollButtons);
      window.addEventListener('resize', checkInitialScroll);

      // Check initial scroll state after a short delay to ensure content is rendered
      setTimeout(() => {
        checkInitialScroll();
        updateScrollButtons();
      }, 100);
    }
    return () => {
      container?.removeEventListener('scroll', updateScrollButtons);
      window.removeEventListener('resize', checkInitialScroll);
    };
  }, [updateScrollButtons, checkInitialScroll]);

  return (
    <>
      <div className="d-flex align-items-start">
        <button
          id="scrollLeft"
          className="time-btn left lg"
          onClick={() => scrollContainer('left')}
          disabled={isScrolledToStart}
        >
          <img src="img/time-left.svg" alt="left-arrow" />
        </button>
        <div className="hourly-grid-wrapper">
          <div className="hourly-grid-container" ref={containerRef}>
            <div className="total-hours lg">
              {hours}
            </div>
          </div>
        </div>
        <button
          id="scrollRight"
          className="time-btn lg"
          onClick={() => scrollContainer('right')}
          disabled={isScrolledToStart ? false : isScrolledToEnd}
        >
          <img src="img/time-right.svg" alt="right-arrow" />
        </button>
      </div>
    </>
  );
}
