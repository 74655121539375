/* eslint-disable react-hooks/exhaustive-deps */
/**PS_01 - Importing of the packages and declaration of state variables */

import { useEffect, useState } from "react";
import InfiniteScroll from 'react-infinite-scroll-component';
import { v4 as uuidv4 } from 'uuid'; // Import the v4 function from uuid
import Timer from "./timer";
import { DeleteWorkItemPayLoad, Message, QueuedItemsPayLoad, SortConfig, TakeWorkItemPayLoad, WorkItemQueueNotificationData, WorkItems } from "../../Interfaces/work_items_interface";
import moment from "moment";
import ToastMessage from "../../reusable_components/toast";
import { deleteWorkItem, getQueuedWorkItems, takeWorkItem } from "../../services/operator_service";
import NoRecordsFoundComponent from "../../reusable_components/noRecordsFoundComponent";
import DeletionConfirmationPopup from "../../reusable_components/delete_popup";
import { useNavigate } from "react-router-dom";
import { baseURL, notificationSubscribe, operatorNotifications, formatDescription } from "../../constant";
import { WorkItemStatus } from "../../enums/enums";


const EscalatedToLane = () => {

  const [escalatedWorkItems, setEscalatedWorkItems] = useState<WorkItems[]>([]); // using mock data for state
  const [recordCount, setRecordCount] = useState(10);
  const [totalRecordCount, setTotalRecordCount] = useState<number>(0)
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState('');
  const [toastMessage, setToastMessage] = useState('');
  const [showConfirmDeletion, setShowConfirmDeletion] = useState(false);
  const [workItemToDelete, setWorkItemToDelete] = useState<WorkItems | null>(null);
  const navigate = useNavigate();
  const [sortConfig, setSortConfig] = useState<SortConfig>({ key: '', direction: 'asc' });
  const webSocketId = uuidv4();



  /**PS_02 - PS_03 invoke the getEscalatedWorkItems in the empty dependency useEffect */
  /**PS_ES_LIVE_03 - PS_ES_LIVE_09 - Establishing the Connection to get the live data in escalated to lane data */
  useEffect(() => {
    getEscalatedWorkItems();
    let modified_url = baseURL ? baseURL.replace("https://", "") : "-"
    const newSocket = new WebSocket(
      `wss:${modified_url}${notificationSubscribe.getLiveSubscribeNotification}${operatorNotifications.getLiveWorkItemsNotification}&subscriptionId=${webSocketId}`
    );

    newSocket.onopen = () => {
      console.log("Connection Opened")
    };

    newSocket.onmessage = (event: any) => {
      let message = JSON.parse(event.data);
      try {
        const data: Message = message.data;
        handleSocketMessage(data);

      } catch (error) {
        console.error("Parsing error:", error);
      }
    };

    // Clean up the WebSocket connection when the component unmounts
    return () => {
      newSocket.close = () => {
        console.log("Connection Closed in escalated to lane")
      };
    }
  }, []);

  /**PS_ES_LIVE_11 - PS_ES_LIVE_20 - Based on the oldStatus and newStatus,the Data is getting manipulated in escalated to lane data */


  const handleSocketMessage = (data: any) => {
    const { oldStatus, newStatus, workItemJson }: WorkItemQueueNotificationData = data;
    if (newStatus === WorkItemStatus.EscalatedToLane || oldStatus === WorkItemStatus.EscalatedToLane) {
      let sessionWorkItems: any = sessionStorage.getItem('escalatedWorkItems');
      // Check if sessionWorkItems is null and initialize updatedWorkItems accordingly
      let updatedWorkItems: any[] = sessionWorkItems ? JSON.parse(sessionWorkItems) : [];
      const existingIndex = updatedWorkItems.findIndex(item => item.work_item_uuid === workItemJson.work_item_uuid);
      // Handle state transitions based on oldState and newState
      if (newStatus === WorkItemStatus.EscalatedToLane) {
        if (existingIndex === -1) {
          updatedWorkItems.push(workItemJson);
          setTotalRecordCount(prevCount => prevCount + 1);
          setEscalatedWorkItems(updatedWorkItems?.slice(0, recordCount));
          sessionStorage.setItem('escalatedWorkItems', JSON.stringify(updatedWorkItems));
        }
      } else if (oldStatus === WorkItemStatus.EscalatedToLane) {
        if (existingIndex !== -1) {
          updatedWorkItems.splice(existingIndex, 1);
          setTotalRecordCount(prevCount => prevCount - 1);
          setEscalatedWorkItems(updatedWorkItems?.slice(0, recordCount));
          sessionStorage.setItem('escalatedWorkItems', JSON.stringify(updatedWorkItems));
        }
      }
    }
  };

  useEffect(() => {
    const sessionWorkItems: any = sessionStorage.getItem('escalatedWorkItems');
    let sorted = JSON.parse(sessionWorkItems);
    sorted?.sort((a: any, b: any) => {
      if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
      if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    });
    // Update state with the sorted items, limiting to recordCount
    setEscalatedWorkItems(sorted ? sorted?.slice(0, recordCount) : []);
  }, [sortConfig])


  /**PS_42 - Function to handle the Sorting of the Escalated Work Items */
  const handleSort = (key: string) => {
    // Update sort configuration state
    setSortConfig(prevConfig => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  /*SQ_EX_22.3 - Helper function to set the renderSortIcon Term */
  const renderSortIcon = (key: string) => {
    const iconSrc = sortConfig.key === key
      ? `img/sort-${sortConfig.direction === 'asc' ? 'up' : 'down'}-arrow.svg`
      : "img/sort-down-arrow.svg";
    return <img src={iconSrc} alt="sort-arrow" />;
  };


  /**PS_03 - PS_21 Function to fetch the escalated Work Items  */
  const getEscalatedWorkItems = async () => {
    try {
      const payload: QueuedItemsPayLoad = {
        work_item_status: "ESCALATED_TO_LANE"
      }
      const response: any = await getQueuedWorkItems(payload)
      if (response?.status === 200) {
        if (response?.data) {
          setTotalRecordCount(response?.data?.data?.length || 0);
          // Store the fetched data in session storage
          sessionStorage.setItem('escalatedWorkItems', JSON.stringify(response.data?.data));
          setEscalatedWorkItems(response.data?.data?.slice(0, recordCount));
        }
      }
    }
    catch (error: any) {
      if (error.message !== "Request failed with status code 404") {
        handleShowToast(true, "Error", error.message);
      }
    }
  }

  /**PS_34 Function to handle the loading of more queued Work Items */

  const handleLoadMore = () => {
    setRecordCount(prevCount => prevCount + 10);
    let sessionWorkItems: any = sessionStorage.getItem('escalatedWorkItems')
    let workItems = JSON.parse(sessionWorkItems);
    setEscalatedWorkItems(workItems?.slice(0, recordCount + 10))
  };

  const deleteEscalatedLaneItem = (items: WorkItems) => {
    setWorkItemToDelete(items);
    setShowConfirmDeletion(true);
  };

  const deleteEscalatedWorkItem = async (workItems: WorkItems) => {
    try {
      let deleteWorkItemPayload: DeleteWorkItemPayLoad = {
        work_item_uuid: workItems?.work_item_uuid
      }
      const response: any = await deleteWorkItem(deleteWorkItemPayload); // Assuming escalateWorkItemAPI is your API call function
      if (response?.status === 200) {
        // Get the current in-progress work items from session storage
        const storedWorkItems = JSON.parse(sessionStorage.getItem('escalatedWorkItems') || '[]');
        // Filter out the escalated item by workItemUUID
        const updatedWorkItems = storedWorkItems?.filter((item: WorkItems) => item.work_item_uuid !== workItems.work_item_uuid);
        // Update session storage with the filtered data
        sessionStorage.setItem('escalatedWorkItems', JSON.stringify(updatedWorkItems));
        // Set the updated work items in the state and adjust total record count
        setEscalatedWorkItems(updatedWorkItems?.slice(0, recordCount));
        setTotalRecordCount(updatedWorkItems?.length);
        // Optionally, show a success toast/message
        handleShowToast(true, "Success", "Work item deleted from escalated lane successfully.");
      }
    } catch (error: any) {
      console.log(error, "error");
      handleShowToast(true, "Error", error.message);

    };
  };

  /**PS_03 - PS_21 Function to fetch the queued Work Items  */
  const invokeTakeWorkItem = async (workItemId: string) => {
    try {
      const payload: TakeWorkItemPayLoad = {
        work_item_uuid: workItemId
      };
      const response: any = await takeWorkItem(payload);
      if (response?.status === 200) {
        if (response?.data) {
          // Navigate to workItemScreen and pass response.data as state
          navigate('/workItemScreen', { state: { workItemData: response.data.data } });
        }
      }
    } catch (error: any) {
      handleShowToast(true, "Error", error.message);
    }
  };


  /**PS_32 Function to handle the rendering of the Escalated Work Items */
  const bindEscalatedWorkItems = (): any => {

    if (totalRecordCount === 0) {
      return <NoRecordsFoundComponent
        colspan={5}
        columnHeight='30'
        mainContent="No Escalate to Lane Work Items Found"
        subContent="Work items will be listed once escalated."
      />
    }
    return escalatedWorkItems?.map((workItem: WorkItems, index) => {
      return (
        <>
          <tr>
            <td>
              <span className="d-flex flex-column gap-1">
                <span
                  data-bs-placement="right"
                  title={workItem?.work_item_name?.length > 10  ? workItem.work_item_name : " "}
                  {...(workItem?.work_item_name && workItem.work_item_name.length > 10 ? { 'data-bs-toggle': '' } : {})}
                >
                  {workItem.work_item_name ? formatDescription(workItem.work_item_name, 10) : "-"}
                </span>
                <span className="font-10 color-sub-grey gap-1">{workItem.queued_on ? moment(workItem.queued_on).format("MM/DD/YYYY HH:mm") : "-"}
                  {workItem.is_assigned && (<span className="cursor-pointer position-relative tooltip-icon">
                    <><img
                      src="img/assigned-icon.svg"
                      alt="assigned-icon" /><span className="custom-tooltip">
                        <span className="color-white font-10">
                          Assigned</span>
                      </span></>
                  </span>)}
                </span>
              </span>
            </td>
            <Timer receivedTime={workItem.queued_on}
              normalTime={workItem.expected_processing_time}
              moderateTime={workItem.max_processing_time}
              isMock={false} />
            <td>
              <span className="d-flex flex-column gap-1">
                <span
                  data-bs-placement="right"
                  title={workItem?.customer_name?.length > 10  ? workItem.customer_name : " "}
                  {...(workItem?.customer_name && workItem.customer_name.length > 10 ? { 'data-bs-toggle': '' } : {})}
                >
                  {workItem.customer_name ? formatDescription(workItem.customer_name, 10) : "-"}
                </span>
                <span className="font-10 color-sub-grey">{workItem.site_name}</span>
              </span>
            </td>
            <td>{workItem.group_code}</td>
            <td className="text-center align-middle">
              <div className="dropdown d-block">
                <button className="border-0 dropdown-btn show" type="button" id={`dropdownMenuButton-${workItem.work_item_uuid}`} data-bs-toggle="dropdown" aria-expanded="false">
                  <img src="img/dots-icon.svg" alt="dots" />
                </button>
                <span className="dropdown-menu" aria-labelledby={`dropdownMenuButton-${workItem.work_item_uuid}`}>
                  <span className="dropdown-item d-flex align-items-center gap-2 py-2 px-3 font-13 font-regular border-bottom" onClick={() => invokeTakeWorkItem(workItem.work_item_uuid)}>
                    <img src="img/take-over-icon.svg" alt="arrows-icon" />
                    Take Work Item</span>
                  <span className="dropdown-item d-flex align-items-center gap-2 py-2 px-3 font-13 font-regular" onClick={() => deleteEscalatedLaneItem(workItem)}>
                    <img src="img/trash-bin.svg" alt="arrows-icon" />
                    Delete</span> </span>
              </div>
            </td>
          </tr>
        </>
      )
    })
  }



  const handleShowToast = (showToast: boolean, toastType: string, toastMessage: string) => {
    setShowToast(showToast);
    setToastType(toastType);
    setToastMessage(toastMessage);
  };

  const confirmDelete = async () => {
    if (workItemToDelete) {
      await deleteEscalatedWorkItem(workItemToDelete);
    }
    setShowConfirmDeletion(false);
  };

  const cancelDelete = () => {
    setShowConfirmDeletion(false);
    setWorkItemToDelete(null); // Clear the delete request if cancelled
  };

  return <>
    {/* Escalate to Lane Work Items */}
    <h2 className="font-16 font-bold color-black mb-3">Escalate to Lane Work Items   {totalRecordCount > 0 ? (
      <span> ({totalRecordCount}) </span>
    ) : ''}
    </h2>
    <div className="table-responsive theme-table bg-white w-100 table-y-scroll rows-4 rounded-3 mb-3" id="scrollIDGRC08">
      <InfiniteScroll
        dataLength={recordCount}
        next={handleLoadMore}
        hasMore={recordCount < totalRecordCount}
        loader={true}
        scrollThreshold={0.6}
        scrollableTarget="scrollIDGRC08"
      >
        <table className="table mb-0">
          <thead className="sticky-top table-header-index">
            <tr className="alter">
              <th>
                {escalatedWorkItems && (
                  <>
                    {totalRecordCount === 0 ? (
                      <span>Work Item</span>
                    ) : (
                      <span className="cursor-pointer" onClick={() => handleSort('work_item_name')}>
                        Work Item {" "}{renderSortIcon('work_item_name')}
                      </span>
                    )}
                  </>
                )}
              </th>
              <th>

                Queued Time
              </th>
              <th>

                {escalatedWorkItems && (
                  <>
                    {totalRecordCount === 0 ? (
                      <span> Customer &amp; Site</span>
                    ) : (
                      <span className="cursor-pointer" onClick={() => handleSort('customer_name')}>
                        Customer &amp; Site {" "}{renderSortIcon('customer_name')}
                      </span>
                    )}
                  </>
                )}
              </th>
              <th>
                {escalatedWorkItems && (
                  <>
                    {totalRecordCount === 0 ? (
                      <span>  Lane / CHE</span>
                    ) : (
                      <span className="cursor-pointer" onClick={() => handleSort('group_code')}>
                        Lane / CHE {" "}{renderSortIcon('group_code')}
                      </span>
                    )}
                  </>
                )}
              </th>
              {totalRecordCount > 0 && (<th className="text-center">Action</th>)}
            </tr>
          </thead>
          <tbody>
            {bindEscalatedWorkItems()}
          </tbody>

        </table>
      </InfiniteScroll>

    </div>
    {showToast && <ToastMessage props={
      {
        isToast: showToast,
        setIsToast: setShowToast,
        toastMessage: toastMessage,
        toastType: toastType
      }
    } />}

    {showConfirmDeletion && (
      <DeletionConfirmationPopup
        message="Are you sure you want to delete this work item?"
        onConfirmDelete={confirmDelete}
        onCancelDelete={cancelDelete}
      />
    )}

  </>
}
export default EscalatedToLane

