import { EncryptStorage } from "encrypt-storage";
import { endpoints } from "./Interfaces/login_interface";
import { breakEndpoints } from "./Interfaces/break_templates_interface";
import { EquipmentCorrectionEndpoints } from "./Interfaces/work_item_interface";
import { CompletedDurationFilters, UserStatus } from "./enums/enums";

export const baseURL: string | undefined = process.env.REACT_APP_BE_URL;

export const authentication: endpoints = {
  generateAuthToken: "/auth/token",
  generateAuthTokenByRefreshToken: "/auth/token/refresh",
};

export const router = {
  applications: "/applications",
};

export const encryptKeys = {
  profile: "yar64876-dhghd6-854687-hjbdfjidhu",
  userDetails: "yar85-8jhgfj-76843-79hfhj-hhipathi",
  role: "enna-y8648h-jguyfu-t75yhe-rvela",
};

const secret: any = process.env.REACT_APP_ENCRYPT_SECRET;

export const encryptStorage = new EncryptStorage(secret ? secret : "", {
  stateManagementUse: true,
  storageType: "sessionStorage",
});

export const operatorConsoleService = {
  getQueuedWorkItems: "/vem/work-item/get-work-items",
  deleteWorkItem: "/vem/work-item/escalate-to-lane",
  escalateToCustomer: "/vem/work-item/escalate-to-customer",
  takeWorkItem: "/vem/work-item/take",
  takeOverWorkItem: "/vem/work-item/takeover",
  assignQueuedWorkItem: "/vem/work-item/assign-work-item",
  getAvailableOperators: "/vem/work-item/get-active-operators",
  getLiveWorkItemsNotification: "/vem-notification/subscribe?topics=WorkItemQueue",
  reArrangeWorkItem: "/vem-work-item-tracker/rearrange-work-item",
  getUserRecentStatus: "/vem/user/status",
  getStatus: "/vem/user-activity/status",
  assignWorkItem: "/vem/work-item/take",
  updateSchedule: "/vem/user-activity/update-schedule",
  getSchedule: "/vem/user-activity/get-current-shift-break",
  getShiftBreakAndTimeZoneDetails: "/vem/break-templates/get-shift-break-timezone-details"
};

export const breakTemplate: breakEndpoints = {
  createBreak: "/vem/break-templates/create",
  editBreak: "/vem/break-templates/update",
  deleteBreak: "/vem/break-templates/delete",

  getBreakDetails: "/vem/break-templates/get",
  getAssociatedTeams: "/vem/break-templates/associated-teams"
};



//PS_15-Function to formate the description
export const formatDescription = (description: string, size: number) => {
  if (description?.length > size) {
    return (description?.slice(0, size + 1) + "...")
  }
  return description
}

// Function to format a comma-separated string into a description
export const formatArrayDescription = (items: string): string => {
  if (!items || items.trim() === '') {
    return '';
  }

  // Split the comma-separated string into an array of strings
  const itemsArray = items.split(',')?.map(item => item.trim())?.filter(item => item !== '');

  // Show the first element and append dots if there are more elements
  const firstItem = itemsArray[0];
  const remainingCount = itemsArray.length - 1;

  if (remainingCount > 0) {
    return `${firstItem} + ${remainingCount} ...`;
  }

  return firstItem;
};

/*SQ_EX_22.3 - Helper function to format the shiftDetails */
export const formatShiftDetails = (input: any): any => {
  const formatTime = (isoString: string): string => {
    const timePart = isoString?.split('T')[1];
    return timePart?.split('.')[0].split('Z')[0];
  };
  return {
    shift_start_time: formatTime(input?.start_time),
    shift_end_time: formatTime(input?.end_time),
    break_ranges: input?.break_details?.map((breakDetail: any) => ({
      break_start_time: formatTime(breakDetail?.break_start_time),
      duration_minutes: breakDetail?.duration_minutes?.toString()
    }))
  };
};


/*SQ_EX_22.3 - Helper function to retreieve the colour code in UI function */
export const determineColorCode = (timeDifference: string, expectedTime: string, maxTime: string): string => {
  if (timeDifference < expectedTime) {
    return 'low';
  } else if (timeDifference >= expectedTime && timeDifference < maxTime) {
    return 'medium';
  } else {
    return 'high';
  }
}

/*SQ_EX_22.3 - Helper function to retreieve the time difference function */
export const calculateTimeDifference = (start: string, end: string) => {
  const startTime = new Date(start).getTime();
  const endTime = new Date(end).getTime();
  const differenceInSeconds = (endTime - startTime) / 1000;
  return differenceInSeconds.toString();
}

// Helper function to format status name
export const formatStatusName = (statusName: string) => {
  if (statusName === UserStatus.DND) {
    return UserStatus.DND;
  }
  return statusName.charAt(0).toUpperCase() + statusName?.slice(1).toLowerCase();
};



export const userActivityService = {
  getAvailableTeams: "/vem/user-activity/teams",
  getUserActivity: "/vem/user-activity",
  getTeamCreatorsInfo: "/vem/user-activity/teams-creation",
  setOperatorStatus: "/vem/user-activity/update-status",
  getOperatorBreakSchedule: "/vem/user-activity/get-current-shift-break",
  overrideOperatorBreakSchedule: "/vem/user-activity/get-current-shift-break",
  getIndividualUserActivity: "/vem/user-activity/user",
  getUserActivityKPI: "/vem/user-activity-kpi",
  getIndividualUserActivityKPI: "/vem/user-activity/user/kpi"
}


export const userActivityNotificationTopics = {
  getIndividualUserActivityTopic: "INDIVIDUAL_USER_ACTIVITY",
  getUserActivityTopic: "USER_ACTIVITY"
}

export const operatorNotifications = {
  getLiveWorkItemsNotification: "?topics=WorkItemQueue"
}

export const notificationSubscribe = {
  getLiveSubscribeNotification: "/vem-notification/subscribe"
}

export const userStatus = {
  IDLE: "Idle",
  WORKING: "Working",
  DND: "Dnd",
  BREAK: "Break",
  OFFLINE: "Offline"
}



export const equipmentCorrection: EquipmentCorrectionEndpoints = {
  escalatetoLane: "/vem/work-item/escalate-to-lane",
  fetchLayoutJson: "/orca-config/get-screen-layout",
  requeueWorkItem: "/requeue-work-item",
  websocket: "/vem-notification/subscribe",
  cameraFootage: "/camera-footage",
  getStatus: "/vem/user-activity/status",
  updateUserActivityStatus: "/vem/user-activity/update-status",
  completeWorkItem: "/vem/complete-work-item",
};

export const workItems = {
  getScheduleDetails: "/vem/user-activity/get-current-shift-break",
  updateActivityStatus: "/vem/user-activity/update-status",
  publishNotification: "/vem-notification/publish"
}

export const notificationWebSocket = {
  connectWebSocket: "/vem-notification/subscribe"
}

export const insightService = {
  getCompletedWorkItems: "/vem/work-items/get-completed-work-items"
}

export const sessionStorageKeyNames = {
  overallUserActivitySessionKey: "userActivityData",
  individualUserActivitySessionKey: "individualUserActivityData"
}

// Function to convert enum value or string to display name
export function getDurationNameFilters(input: CompletedDurationFilters | string): string {
  if (Object.values(CompletedDurationFilters).includes(input as CompletedDurationFilters)) {
      input = input as CompletedDurationFilters;
  } else if (typeof input === 'string') {
      return formatDisplayDurationName(input);
  } else {
      throw new Error('Invalid input type');
  }

  return formatDisplayDurationName(input);
}

// Helper function to format display names
function formatDisplayDurationName(name: string): string {
  return name
      .replace(/_/g, ' ')
      .toLowerCase()
      .split(' ')
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
}

// Set the minimum date to January 1, 2024
export const MinimumFilterDate = "2024-01-01";
export const workItemSla = {
  getCustomerWorkItemAndApplications : "/vem/work-item-sla/cust-site-app-work-details",
  deleteWorkItemSla: "/vem/work-item-sla/delete",
  getWorkItemSla: "/vem/work-item-sla/get",
  createWorkItemSla: "/vem/work-item-sla/create",
  updateWorkItemSla: "/vem/work-item-sla/update",
  getWorkItemSlaById: "/vem/work-item-sla/getById"
}
