/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect, useCallback } from "react";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { encryptKeys, encryptStorage } from "../../constant";
import { userDetails } from "../../Interfaces/login_interface";
import { UserRoles, UserStatus } from "../../enums/enums";
import { getScheduleDetailsService, getStatusService, getUserStatusService, updateUserActivityStatus } from "../../services/operator_service";
import { BreakTiming } from "../../Interfaces/break_templates_interface";
import { ShiftData, UpdateUserStatusInterface } from "../../Interfaces/operations_interface";
import { overallContext } from "../../context/Context";
import { useContext } from "react";
import WebsocketClient from "../../client/websocket";

export default function Header() {

    const userDetail: userDetails = {
        accessToken: "",
        userName: "",
        emailId: "",
        userId: "",
        refreshToken: "",
        appRole: []
    }
    const navigate = useNavigate();
    const { instance } = useMsal();
    const [profileImage, setProfileImage] = useState<string | null>(null);
    const [userDetails, setUserDetails] = useState<userDetails>(userDetail);
    const [userRole, setUserRole] = useState<string | null>(null);
    const [shiftData, setShiftData] = useState<ShiftData>({
        shift_start_time: "",
        shift_end_time: "",
        break_timings: [
            {
                break_start_time: "",
                duration_minutes: ""
            }
        ]
    });
    const [userStatus, setUserStatus] = useState<string>();
    const [status, setStatus] = useState<any[]>([]);

    useEffect(() => {
        // Retrieve the role from session storage
        const storedProfileImage = sessionStorage.getItem(encryptKeys?.profile);
        const storedUserDetails = encryptStorage.getItem(encryptKeys?.userDetails);
        const parsedUserDetails = storedUserDetails ? JSON.parse(storedUserDetails) : null
        const storedRole = parsedUserDetails?.appRole?.filter((app: any) => (app.abbreviation === "VEM"))

        setProfileImage(storedProfileImage);
        setUserDetails(parsedUserDetails);
        setUserRole(storedRole ? storedRole[0]?.role_name : "Unauthorized");
        getScheduleDetails();
        getStatus();
        getUserStatus();
        let user = encryptStorage.getItem(encryptKeys?.userDetails);
        user = JSON?.parse(user);
        const userUUID = user?.userId;
        const newSocket = WebsocketClient(userUUID, [`${userUUID}${UserStatus.LIVEUSERSTATUS}`])
        newSocket.onopen = () => console.log("Connection Opened");
        newSocket.onmessage = handleWebSocketMessage;
    }, []);

    const handleWebSocketMessage = useCallback((event: MessageEvent) => {
        try {
            const message = JSON.parse(event.data);
            console.log(message);
            if (message?.data?.status_name === UserStatus.DND || message?.data?.status_name === UserStatus.BREAK) {
                setUserStatus("dnd")
            } else setUserStatus("active")
        } catch (error) {
            console.error("Parsing error:", error);
        }
    }, []);

    const getInitials = (name: string): string => {
        if (!name) return 'UK';
        const names = name.split(' ');
        return names?.map((n) => n[0]).join('').toUpperCase();
    };

    const onClickLogOut = async () => {
        if (isEquipmentCorrection) return
        try {
            await instance.logoutPopup();
            try {
                const response: any = await getStatusService();
                if (response?.status === 200) {
                    let user = encryptStorage.getItem(encryptKeys?.userDetails);
                    user = JSON?.parse(user);
                    const userUUID = user?.userId;
                    const statusData = response?.data?.data;
                    const foundStatus = statusData.find((item: any) => item.status_name === UserStatus.OFFLINE);
                    let statusUUID = foundStatus?.user_status_uuid;
                    if (statusUUID) {
                        const payload: UpdateUserStatusInterface = {
                            user_status_uuid: statusUUID,
                            status_name: UserStatus.OFFLINE,
                            reason: "Logged Out",
                            category: "Logged Out"
                        }
                        await updateUserStatus(payload);
                    } else {
                        console.error("IDLE status not found");
                    }
                }
            } catch (err: any) {
                console.error("Error fetching status data:", err);
                // You might want to handle this error in the component
                throw err;
            }
            encryptStorage.clear();
            window.location.href = '/';
        } catch (err) {
            console.error("Logout error:", err);
        }
    };

    const formatTime = (timeValue: string) => {
        // Extract the time part from the ISO string
        if (timeValue.includes('T')) {
            const timePart = timeValue.split('T')[1].split('.')[0];
            const [hours, minutes] = timePart.split(':');
            // Pad with leading zeros if necessary
            const formattedHours = hours.padStart(2, '0');
            const formattedMinutes = minutes.padStart(2, '0');
            return `${formattedHours}:${formattedMinutes}`;
        }
        else return timeValue
    }

    const getScheduleDetails = async () => {
        try {
            let user = encryptStorage.getItem(encryptKeys?.userDetails);
            user = JSON?.parse(user);
            const userUUID = user?.userId;
            const response: any = await getScheduleDetailsService(userUUID);
            if (response?.status === 200) {
                const responseData = response.data.data

                if (responseData?.usbo_uuid) {
                    setShiftData((prev: any) => ({
                        ...prev,
                        shift_start_time: formatTime(responseData.override_shift_start),
                        shift_end_time: formatTime(responseData.override_shift_end)
                    }))
                    const formattedBreaks: any[] = responseData.break_override_details.map((breakDetail: any) => ({
                        break_start_time: breakDetail.break_override_uuid ? formatTime(breakDetail.original_break_start) : formatTime(breakDetail.original_break_start),
                        duration_minutes: breakDetail.break_override_uuid ? breakDetail.override_duration_minutes : breakDetail.original_duration_minutes
                    }));
                    setShiftData((prev: any) => ({
                        ...prev,
                        break_timings: formattedBreaks
                    }))
                }
                else {
                    setShiftData((prev: any) => ({
                        ...prev,
                        shift_start_time: formatTime(responseData.original_shift_start),
                        shift_end_time: formatTime(responseData.original_shift_end)
                    }))
                    const formattedBreaks: any[] = responseData.break_details.map((breakDetail: any) => ({
                        break_start_time: formatTime(breakDetail.original_break_start),
                        duration_minutes: breakDetail.original_duration_minutes
                    }));
                    setShiftData((prev: any) => ({
                        ...prev,
                        break_timings: formattedBreaks
                    }))
                }
            }
        } catch (error: any) {
            console.error("Error retrieving schedule details", error);
        }
    };

    const getStatus = async () => {
        try {
            const response: any = await getStatusService();
            if (response?.status === 200) {
                setStatus(response?.data?.data);
                scheduleBreaks();
            }
        } catch (err: any) {
            console.error(err);
        }
    };

    const getUserStatus = async () => {
        try {
            let user = encryptStorage.getItem(encryptKeys?.userDetails);
            user = JSON?.parse(user);
            const userUUID = user?.userId;
            const response: any = await getUserStatusService(userUUID);
            if (response?.status === 200) {
                if (response?.data?.data?.status_name === (UserStatus.DND || UserStatus.BREAK)) {
                    setUserStatus("dnd")
                } else setUserStatus("active")
            }
        } catch (err: any) {
            console.error(err);
        }
    };

    const scheduleBreaks = () => {
        const now = new Date();
        const shiftStart = new Date(now.toDateString() + ' ' + shiftData?.shift_start_time);

        const newActiveBreaks: NodeJS.Timeout[] = [];

        shiftData?.break_timings.forEach((break_time: any) => {
            const [hours, minutes, seconds] = break_time.break_start_time.split(':').map(Number);
            const breakStart = new Date(shiftStart.getTime() + (hours * 3600000 + minutes * 60000 + seconds * 1000));
            const duration = parseInt(break_time.duration_minutes);
            const breakEnd = new Date(breakStart.getTime() + duration * 60000);
            let user = encryptStorage.getItem(encryptKeys?.userDetails);
            user = JSON?.parse(user);
            const userUUID = user?.userId;
            if (breakStart > now) {
                const startTimeout = setTimeout(() => {
                    setBreakStatus(break_time, userUUID);
                    setUserStatus("dnd");
                }, breakStart.getTime() - now.getTime());

                const endTimeout = setTimeout(() => {
                    setIdleStatus(userUUID);
                    setUserStatus("active");
                }, breakEnd.getTime() - now.getTime());

                newActiveBreaks.push(startTimeout, endTimeout);
            } else if (now >= breakStart && now < breakEnd) {
                // If we're currently in a break
                setBreakStatus(break_time, userUUID);
                setUserStatus("dnd");
                const endTimeout = setTimeout(() => {
                    setIdleStatus(userUUID);
                    setUserStatus("active");
                }, breakEnd.getTime() - now.getTime());

                newActiveBreaks.push(endTimeout);
            }
        });
    };

    const setBreakStatus = async (breakTime: BreakTiming, userUUID: string) => {
        try {
            const response: any = await getStatusService();
            if (response?.status === 200) {
                const statusData = response?.data?.data;
                const foundStatus = statusData.find((item: any) => item.status_name === UserStatus.BREAK);
                let statusUUID = foundStatus?.user_status_uuid;
                if (statusUUID) {
                    const payload: UpdateUserStatusInterface = {
                        user_status_uuid: statusUUID,
                        status_name: UserStatus.BREAK,
                        reason: `Scheduled break for ${breakTime.duration_minutes} minutes`,
                        category: UserStatus.BREAK
                    }
                    updateUserStatus(payload);
                } else {
                    console.error("BREAK status not found");
                }
            }
        } catch (err: any) {
            console.error("Error fetching status data:", err);
            // You might want to handle this error in the component
            throw err;
        }
    };

    const setIdleStatus = async (userUUID: string) => {
        try {
            const response: any = await getStatusService();
            if (response?.status === 200) {
                const statusData = response?.data?.data;
                const foundStatus = statusData.find((item: any) => window.location.pathname === '/workItemNotifications' ? (item.status_name === UserStatus.IDLE) : (item.status_name === UserStatus.ACTIVE));
                let statusUUID = foundStatus?.user_status_uuid;
                if (statusUUID) {
                    const payload: UpdateUserStatusInterface = {
                        user_status_uuid: statusUUID,
                        status_name: window.location.pathname === '/workItemNotifications' ? UserStatus.IDLE : UserStatus.ACTIVE,
                        reason: "Break ended",
                        category: window.location.pathname === '/workItemNotifications' ? UserStatus.IDLE : UserStatus.ACTIVE
                    }
                    updateUserStatus(payload);
                } else {
                    console.error("IDLE status not found");
                }
            }
        } catch (err: any) {
            console.error("Error fetching status data:", err);
            // You might want to handle this error in the component
            throw err;
        }
    };

    const updateUserStatus = async (request: UpdateUserStatusInterface) => {
        let user = encryptStorage.getItem(encryptKeys?.userDetails);
        user = JSON?.parse(user);
        const userUUID = user?.userId;
        const payload: UpdateUserStatusInterface = {
            user_uuid: userUUID,
            user_status_uuid: request.user_status_uuid,
            reason: request.reason,
            category: request.category
        };

        try {
            const response: any = await updateUserActivityStatus(payload);
            if (response?.status === 200) {
                if (payload.category === UserStatus.BREAK) {
                    setUserBreak(true)
                }
                else {
                    setUserBreak(false)
                }
            }
        } catch (error) {
            console.error("Error Updating Activity Status", error);
            // You might want to handle this error in the component
            throw error;
        }
    };

    const { isEquipmentCorrection } = useContext(overallContext);
    const { setUserBreak } = useContext(overallContext)

    return (
        <>
            <nav className="navbar navbar-expand-lg theme-header sticky-top">
                <div className="container-fluid px-4">
                    <span className="navbar-brand d-flex align-items-center gap-3 me-5">
                        <img src="img/envision-logo.svg" alt="logo" className="header-logo" />
                    </span>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon" />
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0 d-flex gap-5">
                            {(userRole === UserRoles.supervisor || userRole === UserRoles.admin) &&

                                (
                                    <li className="nav-item dropdown">
                                        <a
                                            className={`nav-link d-flex gap-1 align-items-center ${window.location.pathname.includes('/insights') ? "" : ""}`}
                                            // href="#"
                                            role="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            Insights <img src="img/chevron-blue.svg" alt="dropdown" />
                                        </a>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <span className={`dropdown-item ${!isEquipmentCorrection && "cursor-pointer"}`} onClick={() => { if (isEquipmentCorrection) return; navigate("/insights") }}>
                                                    Completed Work Items
                                                </span>
                                            </li>
                                        </ul>
                                    </li>
                                )
                            }

                            {
                                (userRole === UserRoles.supervisor || userRole === UserRoles.admin || userRole === UserRoles.Operator) &&
                                (
                                    <li className="nav-item dropdown">
                                        <span
                                            className={`nav-link d-flex gap-1 align-items-center ${window.location.pathname.includes('/operations') ? "" : ""}`}
                                            // href="#"
                                            role="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            Operations <img src="img/chevron-blue.svg" alt="dropdown" />
                                        </span>
                                        <ul className="dropdown-menu">
                                            {(userRole === UserRoles.admin || userRole === UserRoles.supervisor) && <li>
                                                <span className={`dropdown-item ${!isEquipmentCorrection && "cursor-pointer"}`} onClick={() => { if (isEquipmentCorrection) return; navigate("/operations") }}>
                                                    Work Item Queue &amp; User Activity
                                                </span>
                                            </li>}
                                            <li>
                                                <span className={`dropdown-item ${!isEquipmentCorrection && "cursor-pointer"}`} onClick={() => { if (isEquipmentCorrection) return; navigate("/workItemNotifications") }}>
                                                    Work Item Queue
                                                </span>
                                            </li>
                                        </ul>
                                    </li>
                                )
                            }

                            {(userRole === UserRoles.admin || userRole === UserRoles.supervisor) && <li className="nav-item dropdown">
                                <a
                                    className={`nav-link d-flex gap-1 align-items-center ${window.location.pathname.includes('/breakTemplate') ? "" : ""}`}
                                    href="#"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    Admin <img src="img/chevron-blue.svg" alt="dropdown" />
                                </a>
                                <ul className="dropdown-menu">
                                    {/* <li>
                                        <a className="dropdown-item" href="#">
                                            User Management
                                        </a>
                                    </li> */}
                                    <li>
                                        <a className={`dropdown-item ${!isEquipmentCorrection && "cursor-pointer"}`} onClick={() => { if (isEquipmentCorrection) return; navigate("/workItemSla") }}>
                                            Work Item SLAs
                                        </a>
                                    </li>
                                    {/* <li>
                                        <a className="dropdown-item" href="#">
                                            Applications
                                        </a>
                                    </li> */}
                                    <li>
                                        <a className={`dropdown-item ${!isEquipmentCorrection && "cursor-pointer"}`} onClick={() => { if (isEquipmentCorrection) return; navigate("/breakTemplate") }}>
                                            Break Templates
                                        </a>
                                    </li>
                                </ul>
                            </li>}
                        </ul>
                        <div className="d-flex gap-3">
                            <img src="img/orca-logo.svg" alt="Orca logo" />
                            <div className="vr header-vr d-none d-lg-block" />
                            {/* Language selector*/}
                            <div className="btn-group language-selector">
                                <button
                                    className="border-0 bg-transparent d-flex align-items-center gap-2"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <img src="img/language-icon.svg" alt="language" />
                                    <span className="font-semibold font-12 color-black">English</span>
                                    <img
                                        src="img/chevron-blue.svg"
                                        alt="dropdown"
                                        className="lang-selector-dpd ms-2"
                                    />
                                </button>
                                <ul className="dropdown-menu border-0 dropdown-menu-end">
                                    <li>
                                        <div className="input-group border bg-white w-100 rounded-2 mb-1">
                                            <input
                                                type="text"
                                                className="form-control font-13 border-0 shadow-none bg-white"
                                                placeholder="Search"
                                                aria-label="Search"
                                            />
                                            <span className="input-group-text border-0 bg-transparent">
                                                <img src="img/search-icon.svg" alt="Search" />
                                            </span>
                                        </div>
                                    </li>
                                    <li>
                                        <ul className="p-0 language-options">
                                            <li className="dropdown-item">
                                                <span>Spanish</span>
                                            </li>
                                            <li className="dropdown-item active">
                                                <span>English</span>{" "}
                                                <img src="img/tick-icon-blue-sm.svg" alt="selected" />
                                            </li>
                                            <li className="dropdown-item">
                                                <span>French</span>
                                            </li>
                                            <li className="dropdown-item">
                                                <span>Italian</span>
                                            </li>
                                            <li className="dropdown-item">
                                                <span>Japanese</span>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                            <div className="vr header-vr d-none d-lg-block" />
                            {/* notification */}
                            <div className="btn-group notification-container-grp">
                                <button
                                    type="button"
                                    className="border-0 bg-transparent d-flex align-items-center position-relative"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    data-bs-auto-close="outside"
                                >
                                    <img src="img/notification-bell-icon.svg" alt="notification" />
                                    <span className="noti-alert position-absolute">4</span>
                                </button>
                                <div className="dropdown-menu border-0 dropdown-menu-end p-0">
                                    <div className="p-3 d-flex align-items-center justify-content-between custom-shadow-sm">
                                        <h3 className="font-bold font-14 color-black-v1">
                                            Notifications
                                        </h3>
                                        <button
                                            className="d-flex align-items-center gap-1 border-0 p-0 bg-transparent primary-color-blue"
                                            type="button"
                                        >
                                            <img src="img/double-tick.svg" alt="double-tick" />
                                            <span className="font-semibold font-12">Mark All as Read</span>
                                        </button>
                                    </div>
                                    {/* Notification content */}
                                    <div className="noti-container-wrapper">
                                        <div className="p-2 noti-threads-wrapper">
                                            <div className="day-noti-container border-bottom mb-2 d-flex flex-column gap-2 pb-2">
                                                <label className="font-semibold font-12 primary-color-blue w-100 ms-2">
                                                    Today
                                                </label>
                                                <div className="notification-thread new d-flex align-items-start p-2 rounded-3 gap-1">
                                                    <span className="d-inline-block notification-dot" />
                                                    <div className="d-flex flex-column gap-2">
                                                        <p className="m-0 d-flex align-items-center gap-1">
                                                            <span className="font-semibold font-10 color-black">
                                                                Work Item
                                                            </span>
                                                            <span className="notification-title-seperator" />
                                                            <span className="font-regular font-9 color-grey-v3">
                                                                1m ago
                                                            </span>
                                                        </p>
                                                        <div className="d-flex flex-wrap align-items-center gap-2">
                                                            <p className="m-0 font-10 lh-1">
                                                                <span className="font-regular color-pale-black">
                                                                    Work Item:
                                                                </span>
                                                                <span className="font-semibold color-black">
                                                                    {" Equipment Correction"}
                                                                </span>
                                                            </p>
                                                            <div className="vr header" />
                                                            <p className="m-0 font-10 lh-1">
                                                                <span className="font-regular color-pale-black">
                                                                    Customer:
                                                                </span>
                                                                <span className="font-semibold color-black">
                                                                    {" LBCT"}
                                                                </span>
                                                            </p>
                                                            <div className="vr header" />
                                                            <p className="m-0 font-10 lh-1">
                                                                <span className="font-regular color-pale-black">
                                                                    Site:
                                                                </span>
                                                                <span className="font-semibold color-black">
                                                                    {" North California"}
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <p className="m-0 font-regular font-10 color-pale-black">
                                                            This transaction has been{" "}
                                                            <span className="font-semibold color-black">
                                                                “requeued”
                                                            </span>{" "}
                                                            multiple times.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="notification-thread d-flex align-items-start p-2 rounded-3 gap-1">
                                                    <span className="d-inline-block notification-dot" />
                                                    <div className="d-flex flex-column gap-2">
                                                        <p className="m-0 d-flex align-items-center gap-1">
                                                            <span className="font-semibold font-10 color-black">
                                                                Work Item
                                                            </span>
                                                            <span className="notification-title-seperator" />
                                                            <span className="font-regular font-9 color-grey-v3">
                                                                1m ago
                                                            </span>
                                                        </p>
                                                        <div className="d-flex flex-wrap align-items-center gap-2">
                                                            <p className="m-0 font-10 lh-1">
                                                                <span className="font-regular color-pale-black">
                                                                    Work Item:
                                                                </span>
                                                                <span className="font-semibold color-black">
                                                                    {" OCR Visit Matchup"}
                                                                </span>
                                                            </p>
                                                            <div className="vr header" />
                                                            <p className="m-0 font-10 lh-1">
                                                                <span className="font-regular color-pale-black">
                                                                    Customer:
                                                                </span>
                                                                <span className="font-semibold color-black">{" APM"}</span>
                                                            </p>
                                                            <div className="vr header" />
                                                            <p className="m-0 font-10 lh-1">
                                                                <span className="font-regular color-pale-black">
                                                                    Site:
                                                                </span>
                                                                <span className="font-semibold color-black">
                                                                    {" Sweden"}
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <p className="m-0 font-regular font-10 color-pale-black">
                                                            This transaction has been{" "}
                                                            <span className="font-semibold color-black">
                                                                “requeued”
                                                            </span>{" "}
                                                            multiple times.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="day-noti-container mb-2 d-flex flex-column gap-2 pb-2 pt-1">
                                                <label className="font-semibold font-12 primary-color-blue w-100 ms-2">
                                                    Yesterday
                                                </label>
                                                <div className="notification-thread d-flex align-items-start p-2 rounded-3 gap-1">
                                                    <span className="d-inline-block notification-dot" />
                                                    <div className="d-flex flex-column gap-2">
                                                        <p className="m-0 d-flex align-items-center gap-1">
                                                            <span className="font-semibold font-10 color-black">
                                                                Work Item
                                                            </span>
                                                            <span className="notification-title-seperator" />
                                                            <span className="font-regular font-9 color-grey-v3">
                                                                1m ago
                                                            </span>
                                                        </p>
                                                        <p className="m-0 font-regular font-10 color-pale-black">
                                                            <span className="font-semibold color-black">
                                                                “Michael Davis”
                                                            </span>{" "}
                                                            working during the <span>10:15</span> break period.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="notification-thread d-flex align-items-start p-2 rounded-3 gap-1">
                                                    <span className="d-inline-block notification-dot" />
                                                    <div className="d-flex flex-column gap-2">
                                                        <p className="m-0 d-flex align-items-center gap-1">
                                                            <span className="font-semibold font-10 color-black">
                                                                Work Item
                                                            </span>
                                                            <span className="notification-title-seperator" />
                                                            <span className="font-regular font-9 color-grey-v3">
                                                                1m ago
                                                            </span>
                                                        </p>
                                                        <p className="m-0 font-regular font-10 color-pale-black">
                                                            <span className="font-semibold color-black">
                                                                “Michael Davis”
                                                            </span>{" "}
                                                            working during the <span>10:15</span> break period.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-center custom-shadow-sm py-3">
                                            <button className="primary-btn text-center" type="button">
                                                View All Notifications
                                            </button>
                                        </div>
                                    </div>
                                    {/* Notification empty */}
                                    <div className="noti-container-empty d-flex align-items-center justify-content-center flex-column gap-1 py-5 d-none">
                                        <img src="img/notification-bell-icon-lg.svg" alt="notification" />
                                        <h3 className="m-0 mt-1 font-semibold font-12 color-black">
                                            No Notifications
                                        </h3>
                                        <p className="m-0 font-semibold font-10 color-grey-v1">
                                            Stay tuned for the updates and alerts.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="vr header-vr d-none d-lg-block" />
                            {/* user settings */}
                            <div className="btn-group user-setting-dpd">
                                <button
                                    type="button"
                                    className="d-flex align-items-center gap-2 border-0 p-0 bg-transparent"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <div className={`position-relative dp-wrapper ${userStatus} user-dp-nouser d-flex justify-content-center align-items-center`}>
                                        {profileImage ? (
                                            <img src={profileImage} alt="user" className="user-dp" />
                                        ) : (
                                            getInitials(userDetails?.userName ?? "")
                                        )}
                                        <span className="user-status d-inline-block bottom-0" />
                                    </div>
                                    <div className="d-flex flex-column justify-content-start">
                                        <label className="font-semibold font-14 color-black cursor-pointer">
                                            {userDetails?.userName}
                                        </label>
                                        <span className="font-semibold font-9 color-grey text-start">
                                            {userRole === UserRoles.admin
                                                ? UserRoles.admins
                                                : userRole === UserRoles.supervisor
                                                    ? UserRoles.supervisors
                                                    : userRole === UserRoles.Operator
                                                        ? UserRoles.Operator
                                                        : null
                                            } {/* Display the user role */}
                                        </span>
                                    </div>
                                </button>
                                <ul className="dropdown-menu dropdown-menu-end border-0 py-1 px-3">
                                    {(userDetails?.appRole?.length ?? 0) > 1 && (
                                        <li>
                                            <span

                                                className="dropdown-item d-flex align-items-center color-black-v1 font-regular font-13 gap-2 p-0 py-2"
                                            >
                                                <img src="img/arrows.svg" alt="arrows" />
                                                <span>Switch Applications</span>
                                            </span>
                                        </li>
                                    )}
                                    <li className="border-bottom">
                                        <span

                                            className={`${!isEquipmentCorrection && "dropdown-item"} d-flex align-items-center color-black-v1 font-regular font-13 gap-2 p-0 py-2`}
                                        >
                                            <img src="img/usericon.svg" alt="user" />
                                            <span className={`${isEquipmentCorrection ? "" : "cursor-pointer"}`} onClick={() => { if (isEquipmentCorrection) return }}>Profile</span>
                                        </span>
                                    </li>
                                    <li>
                                        <span

                                            className="dropdown-item d-flex align-items-center color-black-v1 font-regular font-13 gap-2 p-0 py-2"
                                            onClick={() => onClickLogOut()} >

                                            <img src="img/logout-icon.svg" alt="logout" />
                                            <span className={`${isEquipmentCorrection ? "color-red" : "color-red cursor-pointer"}`}> Logout</span>
                                        </span>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </nav >


        </>
    )
}

