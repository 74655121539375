/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";

//PS_01 to PS_43
export const VisitMatchupPanel = ({
  options,
  onChange,
  schema,
  formContext,
}: any) => {
  const { workItemJSON, handleSubmit, setMatch,navigationProps } = formContext;
  const originalJSON = JSON.parse(JSON.stringify(workItemJSON))
  const { equipments, equipmentDetails } = options;
  const { submit, deselect } = options?.navigationShortcuts;
  const {completed}= navigationProps
  const [selectedEquipment, setSelectedEquipment] = useState<any>(null);
  const [newWorkItemJSON, setNewWorkItemJSON] = useState<any>(originalJSON);
  const [preSelectedEquipment, setPreSelectedEquipment] = useState<any>(null);
  const [backupSelectedEquipment, setBackupSelectedEquipment] = useState<any>(null)
  const [disableSubmitButton, setDisableSubmitButton] = useState<boolean>(true);
  const handleItemSelect = useCallback(
    (itemId: string) => {
      if(completed) return;
      let newSelectedEquipment = equipments?.find(
        (eachEquipment: any) => eachEquipment?.id === itemId
      );
      setSelectedEquipment(newSelectedEquipment);
      const {
        containerNameJsonKey,
        containerEquipmentAttachedJsonKey,
        defaultAttachedEquipment,
        name,
      } = newSelectedEquipment;
      setNewWorkItemJSON((prevWorkItemkJson: any) => {
        let updatedWorkItemJson = updateNestedProperty(
          prevWorkItemkJson,
          containerNameJsonKey,
          name,
          -1
        );
        updatedWorkItemJson = updateNestedProperty(
          updatedWorkItemJson,
          containerEquipmentAttachedJsonKey,
          defaultAttachedEquipment,
          -1
        );
        updatedWorkItemJson.scan_data.truck.license_plate.value = ""
        updatedWorkItemJson.scan_data.equipment.value = ""
        for (let eachEquipment of updatedWorkItemJson?.scan_data?.equipment
          ?.equipment_attached) {
          eachEquipment.value = ""
        }
        return updatedWorkItemJson;
      });
      setMatch(false)
    },
    [equipments,completed]
  );

  useEffect(() => {
    let disable = false;
    if (newWorkItemJSON?.scan_data?.classification === "Bobtail") {
      setDisableSubmitButton(false)
      return
    }
    if (newWorkItemJSON) {
      // if (newWorkItemJSON?.scan_data?.classification !== "Bare Chassis") {
      //   if (newWorkItemJSON?.scan_data?.truck?.license_plate?.value === "") {
      //     disable = true;
      //   }
      // }
      if (newWorkItemJSON?.scan_data?.classification !== "Flat Rack") {
        if (newWorkItemJSON?.scan_data?.equipment?.value.trim() === "") {
          disable = true;
        }
      }
      for (let eachEquipment of newWorkItemJSON?.scan_data?.equipment
        ?.equipment_attached) {
        if (eachEquipment?.value.trim() === "") {
          disable = true;
          break;
        }
      }
    }
    setDisableSubmitButton(disable);
  }, [newWorkItemJSON, selectedEquipment]);
  useEffect(() => {
    if (workItemJSON) {
      let matchedEquipment = equipments?.find(
        (eachEquipment: any) =>
          eachEquipment?.name === workItemJSON?.scan_data?.classification
      );
      setPreSelectedEquipment(matchedEquipment);
      if(completed){
        setSelectedEquipment(matchedEquipment)
      }
    }
  }, [options, equipments, workItemJSON]);
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if(completed) return;
      const { key } = event;
      if (selectedEquipment) {
        switch (key) {
          case submit:
            if (disableSubmitButton === false) {
              handleSubmit(newWorkItemJSON);
            }
            break;
          case deselect:
            setSelectedEquipment(null);
            break;
        }
      } else {
        const selectedId = getEquipmentIdByShortcut(equipments, key);
        if (selectedId) {
          handleItemSelect(selectedId);
        }
      }
    }
    window.addEventListener("keydown", handleKeyPress);
    return () => window.removeEventListener("keydown", handleKeyPress);
  }, [
    selectedEquipment,
    equipments,
    handleItemSelect,
    setSelectedEquipment,
    handleSubmit,
    deselect,
    submit,
    newWorkItemJSON,
    disableSubmitButton,
    completed
  ]);

  const updateNestedProperty = (
    obj: any,
    keys: any,
    newValue: any,
    position: any
  ) => {
    const updatedObj = { ...obj };
    let current = updatedObj;
    keys.forEach((key: any, index: any) => {
      if (index === keys.length - 1) {
        if (position !== -1) {
          current[position][key] = newValue;
        } else {
          current[key] = newValue;
        }
      } else {
        if (Array.isArray(current[key])) {
          current[key] = [...current[key]];
        } else {
          current[key] = { ...current[key] };
        }
        current = current[key];
      }
    });
    return updatedObj;
  }
  const getNestedProperty = (obj: any, keys: any, position: any) => {
    const updatedObj = { ...obj };
    let current = updatedObj;
    let value = "";
    keys.forEach((key: any, index: any) => {
      if (index === keys?.length - 1) {
        if (position !== -1) {
          value = current?.[position]?.[key];
        } else {
          value = current?.[key];
        }
      } else {
        if (Array.isArray(current?.[key])) {
          current[key] = [...current?.[key]];
        } else {
          current[key] = { ...current?.[key] };
        }
        current = current?.[key];
      }
    });
    return value;
  }
  const getEquipmentIdByShortcut = (equipments: any, shortcut: string) => {
    let item = equipments?.find(
      (eachEquipment: any) => eachEquipment?.shortcut === shortcut
    );
    return item?.id;
  }
  const handleChange = (event: any, containerSequence: any, jsonKeys: any, index: any) => {
    if (containerSequence === undefined) {
      containerSequence = -1
    }
    let updatedNewWorkItemJson = updateNestedProperty(
      newWorkItemJSON,
      jsonKeys,
      event.target.value,
      containerSequence
    );
    setNewWorkItemJSON(updatedNewWorkItemJson)
  }

  const bindEquipmentDetails = () => {
    return equipmentDetails[selectedEquipment.id]?.length > 0 ? (
      <>
        {equipmentDetails[selectedEquipment?.id]?.map(
          (container: any, index: any) => {
            let value: any = "";
            let trueValue: any = "";
            let inputClassName: any = "";
            let indicatorClassName: any = ""
            let confidenceKey: any = [...container?.jsonKeys];
            let portalPassageText: any = "";
            confidenceKey[confidenceKey.length - 1] = "confidence";

            if (container?.sequence !== undefined) {
              value = getNestedProperty(
                newWorkItemJSON,
                container?.jsonKeys,
                container?.sequence
              );
              if (selectedEquipment === preSelectedEquipment) {
                trueValue = getNestedProperty(
                  originalJSON,
                  container?.jsonKeys,
                  container?.sequence
                );
              }
            } else {
              value = getNestedProperty(
                newWorkItemJSON,
                container?.jsonKeys,
                -1
              );
              if (selectedEquipment === preSelectedEquipment) {
                trueValue = getNestedProperty(
                  originalJSON,
                  container?.jsonKeys,
                  -1
                );
              }
            }
            if (selectedEquipment === preSelectedEquipment) {
              if (value === trueValue) {
                indicatorClassName = "color-green-v1";
                portalPassageText = "Portal Passage Found!";
                inputClassName = "input-success"
                if (index === 0) {
                  if (backupSelectedEquipment === null) {
                    let modifiedWorkItemJSON = updateNestedProperty(newWorkItemJSON, container?.jsonKeys, value.slice(0, -1), container?.sequence)
                    setBackupSelectedEquipment(modifiedWorkItemJSON)
                    setNewWorkItemJSON(originalJSON)
                    setMatch(true)
                  }
                }
              }
              else {
                indicatorClassName = "color-red";
                portalPassageText = "Portal Passage No Match!";
                inputClassName = "input-correction"
                if (index === 0) {
                  if (backupSelectedEquipment !== null) {
                    setNewWorkItemJSON(backupSelectedEquipment)
                    setBackupSelectedEquipment(null)
                    setMatch(false)
                  }
                }
              }
              if (value?.length === 0) {
                indicatorClassName = "";
                portalPassageText = "";
                inputClassName = "input-grp-border"
              }
            } else {
              indicatorClassName = "";
              inputClassName = "input-grp-border"
              portalPassageText = ""
            }
            if(completed) {
              inputClassName =""
               portalPassageText = ""
              }
            return (
              <div className="mb-3" key={index}>
                <label
                  htmlFor={`container_${index}`}
                  className="m-0 font-semibold font-12 color-black-v1 mb-2"
                >
                  {container?.label}
                </label>
                <div
                  className={`input-group rounded-2 overflow-hidden ${inputClassName}`}
                >
                  <input
                    id={`container_${index}`}
                    type="text"
                    className={`form-control font-12 shadow-none ${inputClassName !== "inputCorrection" ? "" : "border-0"}`}
                    placeholder={container.placeholder}
                    aria-label={`Container #${index + 1}`}
                    name={container?.name}
                    value={value}
                    maxLength={255}
                    onChange={(event: any) =>
                      handleChange(
                        event,
                        container?.sequence,
                        container?.jsonKeys,
                        index
                      )
                    }
                    disabled={(value === trueValue && selectedEquipment === preSelectedEquipment && index !== 0) || completed}
                  />
                  {index !== 0 && value === trueValue && selectedEquipment === preSelectedEquipment && !completed &&  (
                    <span className="input-group-text border-0 bg-transparent">
                      <img src="img/tick-icon-green.svg" alt="cancel" />
                    </span>
                  )}
                </div>
                {
                  indicatorClassName && portalPassageText && index === 0 && (
                    <span className={`font-semibold font-10 ${indicatorClassName}`}>
                      {portalPassageText}
                    </span>
                  )
                }
              </div >
            );
          }
        )}
        {!completed && (
        <button
          className="mt-auto primary-btn d-flex align-items-center justify-content-center gap-2 align-self-end"
          type="button"
          disabled={disableSubmitButton}
          onClick={() => {
            handleSubmit(newWorkItemJSON);
          }}
        >
          <span>Done</span>
          <span className="inner-btn">Enter</span>
        </button>)}
      </>
    ) : (
      <>
        <p className="font-semibold font-15 color-black text-center py-0">
          Are you sure this is a Bobtail?
        </p>
        <div className="mt-auto d-flex align-items-center justify-content-center gap-2 align-self-end">
          <button
            className="mt-auto secondary-btn no-btn d-flex align-items-center justify-content-center gap-2 align-self-end"
            type="button"
            onClick={() => setSelectedEquipment(null)}
          >
            <span>No</span>
          </button>
          <button
            className="mt-auto primary-btn d-flex align-items-center justify-content-center gap-2 align-self-end"
            type="button"
            onClick={() => handleSubmit(newWorkItemJSON)}
          >
            <span>Yes</span>
            <span className="inner-btn">Enter</span>
          </button>
        </div>
      </>
    );
  }

  return (
    <div className="p-3 h-100">
      <div className="row min-h-75vh h-100">
        <div className="col-md-6 border-end h-100">
          <div className="d-flex align-items-center gap-2 pb-2">
            <span className="inset-icon-wrapper d-flex align-items-center justify-content-center">
              <img src="img/truck-icon.svg" alt="truck" />
            </span>
            <h2 className="font-bold font-14 color-black m-0">
              {schema.title}
            </h2>
            <span className="cursor-pointer position-relative tooltip-icon">
              <img
                src="img/tooltip-icon.svg"
                alt="sort-arrow-down"
                style={{ marginBottom: "2px" }}
              />
              <span className="custom-tooltip">
                <span className="color-white text-center font-regular">
                  Examine the images below to identify the truck's load type,
                  then select the appropriate option from the list below.
                </span>
              </span>
            </span>
          </div>
          <div className="items-wrapper">
            {options?.equipments?.map((item: any, index: any) => (
              <div
                key={index}
                className={`d-flex align-items-center py-3 px-1 border-bottom cursor-pointer item-select ${item.id === selectedEquipment?.id ? "active" : ""
                  }`}
                onClick={() => handleItemSelect(item.id)}
              >
                <div className="w-25">
                  <img src={item.image} alt={item.name} />
                </div>
                <span className="font-semibold font-12 color-black">
                  {item.name}
                </span>
                <span className="ms-auto keyboard-press font-regular font-10 d-flex align-items-center justify-content-center">
                  {item.shortcut}
                </span>
              </div>
            ))}
          </div>
        </div>
        <div className="col-md-6 h-100">
          <div className="field-container min-h-75vh d-flex flex-column h-100">
            <div className="d-flex align-items-center gap-2 mb-3">
              <span className="inset-icon-wrapper d-flex align-items-center justify-content-center">
                <img src="img/product-details-icon.svg" alt="product details" />
              </span>
              <h2 className="font-bold font-14 color-black m-0">Details</h2>
              <span className="cursor-pointer position-relative tooltip-icon">
                <img
                  src="img/tooltip-icon.svg"
                  alt="sort-arrow-down"
                  style={{ marginBottom: "2px" }}
                />
                <span className="custom-tooltip">
                  <span className="color-white text-center font-regular">
                    Please provide details about the chassis and containers involved in this visit.
                    If details match portal passage, it will be auto-found.
                    If no match, manually input the information.
                  </span>
                </span>
              </span>
            </div>
            {selectedEquipment?.id ? (
              bindEquipmentDetails()
            ) : (
              <div className="vh-50 d-flex align-items-center justify-content-center gap-3 flex-column ">
                <img src="img/list-icon.svg" alt="list" />
                <p className="font-semibold font-12 color-black text-center">
                  Select Equipment Classification to Continue.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
