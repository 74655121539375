/* eslint-disable react-hooks/exhaustive-deps */
import Form from "@rjsf/core";
import "../../App.css";
import { RegistryWidgetsType, RJSFSchema, UiSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import { useCallback, useEffect, useRef, useState } from "react";
import { LiveFeedPanel } from "../../reusable_components/panels/live_feed_panel";
import { EquipmentClassificationPanel } from "../../reusable_components/panels/equipment_classification_panel";
import { StatusBarPanel } from "../../reusable_components/panels/status_bar_panel";
import ObjectFieldTemplate from "../../reusable_components/templates/column_object_field_template";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CompletedWorkItemRequest,
  EscalateToLaneRequest,
  FetchLayoutJsonRequest,
  UpdateUserStatusRequest,
} from "../../Interfaces/work_item_interface";
import { encryptKeys, encryptStorage } from "../../constant";
import {
  completedWorkItemService,
  escalatetoLaneService,
  fetchLayoutJsonService,
  getStatusService,
  updateUserActivityStatusService,
} from "../../services/work_item_service";
import { FullscreenLoader } from "../loader";
import { VisitMatchupPanel } from "../../reusable_components/panels/visit_matchup_panel";
import { UserStatus, WebsocketCodes } from "../../enums/enums";
import WebsocketClient from "../../client/websocket";
import { overallContext } from "../../context/Context";
import { useContext } from "react";

// PS_01 to PS_13
const WorkItem = () => {
  const { setIsEquipmentCorrection } = useContext(overallContext);
  const navigate = useNavigate();
  let navigationProps = JSON.parse(JSON.stringify(useLocation().state))
  navigationProps.portal_data = JSON.parse(navigationProps.portal_data)
  const workItemJSON = useRef<any>(navigationProps.portal_data)
  const websocket = useRef<WebSocket>()
  const startTime = useRef<string>(new Date().toISOString());
  const [loader, setLoader] = useState<boolean>(false);
  const [match, setMatch] = useState<boolean>(true)
  const jsonSchema = useRef<RJSFSchema>({})
  const uiSchema = useRef<UiSchema>({
    "ui:submitButtonOptions": {
      norender: true,
    },
  });
  const widgets: RegistryWidgetsType = {
    EquipmentClassificationPanel,
    StatusBarPanel,
    LiveFeedPanel,
    VisitMatchupPanel,
  };
  const { lane_id,lane_name } = navigationProps;
  const [laneDetails, setLaneDetails] = useState<any>({
    lane_id: lane_id,
    lane_name: lane_name
  });

  const fetchLayoutJson = useCallback(async () => {
    try {
      const { customer_uuid, app_work_item_uuid ,work_item_type} = navigationProps;
      const payload: FetchLayoutJsonRequest = {
        customer_uuid,
        app_work_item_uuid,
        work_item_type : work_item_type
      };
      const response = await fetchLayoutJsonService(payload);
      if (response?.status === 200) {
        jsonSchema.current = response?.data?.jsonSchema;
        uiSchema.current = response?.data?.uiSchema;
      } else {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      console.log("an error occured: ", error);
    }
  }, [navigationProps]);

  //PS_48 to PS_53
  const handleOnMessage = useCallback(
    async (event: any) => {
      const { work_item_id } = navigationProps
      try {
        const receivedMessage = JSON.parse(event.data);
        if (receivedMessage?.data?.type === WebsocketCodes.TAKEOVER && receivedMessage?.data?.work_item_uuid === work_item_id) {
          setIsEquipmentCorrection(false)
          await UpdateUserStatus(UserStatus.IDLE, "Work item has been take over by the supervisor")
          navigate("/workItemNotifications", { replace: true, state: { "takeover": true } });
        } else if (receivedMessage?.data?.type === WebsocketCodes.LANEUPDATE) {
          setLaneDetails(receivedMessage?.data);
        }
      } catch (error: any) {
        console.log("an error occurred: ", error);
      }
    },
    [navigate]
  );
  const handleOnError = useCallback((error: any) => {
    console.log("an error occured in websocket:", error);
  }, []);

  const loadInitialPageData = async () => {
    setLoader(true);
    await fetchLayoutJson();
    setLoader(false);
  }

  useEffect(() => {
    const userId = fetchUserUUIDFromStorage();
    if (!navigationProps?.completed) {
      const socket = WebsocketClient(userId, ["a1", "a2"]);
      websocket.current = socket;
      socket.onmessage = handleOnMessage;
      socket.onerror = handleOnError;
    }
    loadInitialPageData();
    return () => {
      if (websocket.current) {
        websocket.current.close()
      }
    };
  }, [handleOnError, handleOnMessage]);

  const UpdateUserStatus = async (category: UserStatus, reason: string) => {
    try {
      const statusResponse = await getStatusService();
      if (statusResponse?.status !== 200) {
        throw new Error(statusResponse?.error);
      }
      const { user_status_uuid } = statusResponse?.data?.data?.find(
        (eachStatus: any) => eachStatus.status_name === category
      );
      if (!user_status_uuid) {
        throw new Error(`${category} uuid not found`);
      }
      const payload: UpdateUserStatusRequest = {
        user_uuid: fetchUserUUIDFromStorage(),
        user_status_uuid: user_status_uuid,
        reason: reason,
        category: category,
      };
      const response = await updateUserActivityStatusService(payload);
      if (response?.status !== 200) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      console.log("An error occured: ", error)
    }
  }

  useEffect(() => {
    setIsEquipmentCorrection(true);
  }, [])

  const fetchUserUUIDFromStorage = (): any => {
    const storedUserDetails = encryptStorage.getItem(encryptKeys?.userDetails);
    const parsedUserDetails = storedUserDetails
      ? JSON.parse(storedUserDetails)
      : null;
    return parsedUserDetails?.userId;
  }
  const calculateDurationInSeconds = (
    isoString1: string,
    isoString2: string
  ): number => {
    const date1 = new Date(isoString1);
    const date2 = new Date(isoString2);

    const differenceInSeconds = Math.abs(
      (date2.getTime() - date1.getTime()) / 1000
    );

    return differenceInSeconds;
  }
  //PS_40 to PS_47
  const handleSubmit = async (newPortalJson: any) => {
    try {
      console.log("Original JSON", workItemJSON.current)
      console.log("New JSON", newPortalJson)
      const completedOn = new Date().toISOString();
      const completionDurationSec = calculateDurationInSeconds(
        startTime.current,
        completedOn
      );
      const { work_item_uuid, app_work_item_uuid } = navigationProps;
      const payload: CompletedWorkItemRequest = {
        work_item_id: work_item_uuid,
        work_item_type_id: app_work_item_uuid,
        started_on: startTime.current,
        completed_on: completedOn,
        completion_duration_sec: completionDurationSec,
        completed_work_item_data: newPortalJson,
      };
      const response = await completedWorkItemService(payload);
      if (response?.status !== 200) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      console.log("an error occured: ", error);
    }
    setIsEquipmentCorrection(false)
    await UpdateUserStatus(UserStatus.IDLE, "The Operator has completed the work item")
    navigate("/workItemNotifications", { replace: true });
  }
  //PS_14 to PS_23
  const handleEscalateToLane = async () => {
    try {
      const { work_item_uuid } = navigationProps;
      const payload: EscalateToLaneRequest = {
        work_item_uuid,
      };
      const response = await escalatetoLaneService(payload);
      if (response?.status !== 200) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      console.log("an error occured: ", error);
    }
    setIsEquipmentCorrection(false)
    await UpdateUserStatus(UserStatus.IDLE, "The user has escalated the work item to the lane")
    navigate("/workItemNotifications", { replace: true });
  }

  const handleReorderWorkItem = async (setOperatorToDnd: boolean) => {
    if (setOperatorToDnd) {
      try {
        await UpdateUserStatus(UserStatus.DND, "Operator did not work in work item for too long")
      } catch (error: any) {
        console.log("an error occured: ", error);
      }
    }
    await UpdateUserStatus(UserStatus.IDLE, "The operator no longer works on the work item")
    setIsEquipmentCorrection(false)
    navigate("/workItemNotifications", { replace: true });
  }

  return (
    <>
      {loader ? (
        <FullscreenLoader />
      ) : (
        <Form
          schema={jsonSchema.current}
          uiSchema={uiSchema.current}
          templates={{ ObjectFieldTemplate }}
          validator={validator}
          onSubmit={handleSubmit}
          formContext={{
            workItemJSON: workItemJSON.current,
            handleSubmit,
            handleEscalateToLane,
            handleReorderWorkItem,
            laneDetails,
            navigationProps,
            match,
            setMatch
          }}
          widgets={widgets}
        />
      )}
    </>
  );
};

export default WorkItem;